 export const featuredProductsData = [
  {
    "id": 1,
    "title": "Aputure LS 600D Pro",
    "image": require("../assets/Posters/aputure_ls_600d_pro.webp"),
  },
  {
    "id": 2,
    "title": "Aputure MC Pro",
    "image": require("../assets/Posters/aputure_mc_pro.webp")
  },
  {
    "id": 3,
    "title": "Atomos Shogun",
    "image": require("../assets/Posters/atomos_shogun.webp")
  },
  {
    "id": 4,
    "title": "Sony FX3 Cinema Line Camera",
    "image": require("../assets/Posters/fx3_fullframe_cinema.webp")
  },
  {
    "id": 5,
    "title": "Sony FX6 Cinema Line Camera",
    "image": require("../assets/Posters/fx6_cinema.webp")
  },
  {
    "id": 6,
    "title": "Sony FX9 Cinema Line Camera",
    "image": require("../assets/Posters/fx9_cinema.webp")
  },
  {
    "id": 7,
    "title": "Go Pro Hero 9",
    "image": require("../assets/Posters/go_pro_hero_9.webp")
  },
  {
    "id": 8,
    "title": "Go Pro Hero 10",
    "image": require("../assets/Posters/gopro_hero10.webp")
  },
  {
    "id": 9,
    "title": "Go Pro Max 360",
    "image": require("../assets/Posters/gopro_max360.webp")
  },
  {
    "id": 10,
    "title": "Lanparte Combo Shoulder Rig kit",
    "image": require("../assets/Posters/lanparte_kit.webp")
  },
  {
    "id": 11,
    "title": "Lilliput 17",
    "image": require("../assets/Posters/lilliput_17.webp")
  },
  {
    "id": 12,
    "title": "Lilliput A7",
    "image": require("../assets/Posters/Lilliput_A7.webp")
  },
  {
    "id": 13,
    "title": "Sony 24-70 MM GM II",
    "image": require("../assets/Posters/sony_24-70mm_gm2.webp")
  },
  {
    "id": 14,
    "title": "Sony A7S III",
    "image": require("../assets/Posters/sony_a7s_3.webp")
  },
  {
    "id": 15,
    "title": "Sony Alpha 1",
    "image": require("../assets/Posters/sony_alpha_1.webp")
  },
  {
    "id": 16,
    "title": "Sony Alpha 7R IV",
    "image": require("../assets/Posters/sony_alpha_7r_4.webp")
  },
  {
    "id": 17,
    "title": "Sony Alpha 7S III",
    "image": require("../assets/Posters/sony_alpha_7s_3.webp")
  },
  {
    "id": 18,
    "title": "Sony Alpha 7 III",
    "image": require("../assets/Posters/sony_alpha7_3.webp")
  },
  {
    "id": 19,
    "title": "Sony Alpha 7 IV",
    "image": require("../assets/Posters/sony_alpha7_4.webp")
  },
  {
    "id": 20,
    "title": "Sony FE 35mm G-Master F1.4",
    "image": require("../assets/Posters/sony_fe_35mm_gmaster.webp")
  },
  {
    "id": 21,
    "title": "Sony FE 50mm G-Master F1.4",
    "image": require("../assets/Posters/sony_fe_50mm.webp")
  },
  {
    "id": 22,
    "title": "Sony FE 50mm G-Master F1.2",
    "image": require("../assets/Posters/sony_fe_50mm_gmaster.webp")
  },
  {
    "id": 23,
    "title": "Sony FE 85mm G-Master F1.4",
    "image": require("../assets/Posters/sony_fe_85mm_gmaster.webp")
  },
  {
    "id": 24,
    "title": "Sony FE 200-600 F5.6-6.3 G OSS",
    "image": require("../assets/Posters/sony_fe_200-600mm.webp")
  },
  {
    "id": 25,
    "title": "Sony FE 14mm G-Master F1.8",
    "image": require("../assets/Posters/sony_fe14mm_gmaster.webp")
  },
  {
    "id": 26,
    "title": "Sony FE 24mm G-Master F1.4",
    "image": require("../assets/Posters/sony_fe24mm_gmaster.webp")
  },
  {
    "id": 27,
    "title": "Sony FE 35mm G-Master F1.4",
    "image": require("../assets/Posters/sony_fe35mm_gmaster.webp")
  },
  {
    "id": 28,
    "title": "Sony FE 135mm G-Master F1.8",
    "image": require("../assets/Posters/sony_fe135mm_gmaster.webp")
  },
  {
    "id": 29,
    "title": "Sony G-Master Prime Lens Kit",
    "image": require("../assets/Posters/sony_gmaster_lenskit.webp")
  },
  {
    "id": 30,
    "title": "Sony PXW 200 XDCAM camcoder",
    "image": require("../assets/Posters/sony_pxw_200_xdcam.webp")
  }
]

