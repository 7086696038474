export const productsData = {
  products: [
    {
      category: "Cameras",
      items: [
        {
          id: 1,
          title: "Canon 5D Mark IV",
          image: require("../assets/equipment/cameras/canon_5d_mark_4.webp"),
          category: "Camera",
          description:
            "The Canon 5D Mark IV is a professional-grade DSLR known for its robust build and high performance. Featuring a 30.4 MP full-frame CMOS sensor, it delivers stunning image quality with excellent detail and dynamic range. It supports 4K video recording, making it a versatile choice for both photographers and videographers. The camera also boasts a fast and accurate 61-point AF system, a 7 fps continuous shooting rate, and built-in GPS and Wi-Fi for easy connectivity and geotagging.",
            specifications: {
              "Sensor": "30.4 MP full-frame CMOS",
              "ISO Range": "100-32000 (expandable to 50-102400)",
              "Autofocus Points": "61-point AF system",
              "Continuous Shooting Speed": "7 fps",
              "Video Resolution": "4K (4096 x 2160)",
              "Connectivity": "GPS, Wi-Fi, NFC",
              "Weight": "890g (with battery and memory card)"
            }
        },
        {
          id: 2,
          title: "Camera R6 Mark II",
          image: require("../assets/equipment/cameras/canon_r6_mark_2.webp"),
          category: "Camera",
          description:
            "The Canon R6 Mark II is a versatile mirrorless camera designed for both photography and videography. It features a 20.1 MP full-frame sensor, which delivers superb image quality and low-light performance. The camera offers in-body image stabilization, which helps in capturing sharp images even in challenging conditions. It can shoot 4K video at up to 60 fps and has a fast and precise autofocus system with Dual Pixel CMOS AF II, making it an excellent choice for action and wildlife photography.",
            specifications: {
              "Sensor": "20.1 MP full-frame",
              "ISO Range": "100-102400 (expandable to 50-204800)",
              "Autofocus Points": "Dual Pixel CMOS AF II",
              "Continuous Shooting Speed": "12 fps (mechanical), 20 fps (electronic)",
              "Video Resolution": "4K (3840 x 2160) at 60 fps",
              "Connectivity": "Wi-Fi, Bluetooth",
              "Weight": "680g (body only)"
            }
        },
        {
          id: 3,
          title: "Canon R5",
          image: require("../assets/equipment/cameras/canon_r5.webp"),
          category: "Camera",
          description:
            "The Canon R5 is a high-resolution mirrorless camera that pushes the boundaries of image quality and performance. It boasts a 45 MP full-frame sensor, capable of producing incredibly detailed images. The camera supports 8K video recording, making it one of the most advanced video-capable cameras on the market. It features advanced autofocus with Dual Pixel CMOS AF II, in-body image stabilization, and a high-resolution electronic viewfinder, making it perfect for both professional photographers and videographers.",
            specifications: {
              "Sensor": "45 MP full-frame",
              "ISO Range": "100-51200 (expandable to 50-102400)",
              "Autofocus Points": "Dual Pixel CMOS AF II",
              "Continuous Shooting Speed": "12 fps (mechanical), 20 fps (electronic)",
              "Video Resolution": "8K (8192 x 4320) at 30 fps",
              "Connectivity": "Wi-Fi, Bluetooth",
              "Weight": "738g (body only)"
            }
        },
        {
          id: 4,
          title: "Sony A7 IV",
          image: require("../assets/equipment/cameras/sony_a7_4.webp"),
          category: "Camera",
          description:
            "The Sony A7 IV is a full-frame mirrorless camera that offers a balanced combination of resolution, speed, and video capabilities. With a 33 MP sensor, it delivers high-quality images with excellent dynamic range. The camera features real-time tracking autofocus and Eye AF for both humans and animals, ensuring sharp focus in challenging shooting conditions. It can shoot 4K video at up to 60 fps and includes 5-axis in-body stabilization, making it a versatile tool for both stills and video.",
            specifications: {
              "Sensor": "33 MP full-frame",
              "ISO Range": "100-51200 (expandable to 50-204800)",
              "Autofocus Points": "693 phase-detection points",
              "Continuous Shooting Speed": "10 fps",
              "Video Resolution": "4K (3840 x 2160) at 60 fps",
              "Connectivity": "Wi-Fi, Bluetooth, USB-C",
              "Weight": "650g (body only)"
            }
        },
        {
          id: 5,
          title: "Sony A7 S III",
          image: require("../assets/equipment/cameras/sony_a7_s3.webp"),
          category: "Camera",
          description:
            "The Sony A7S III is renowned for its exceptional low-light performance and video capabilities. It features a 12.1 MP full-frame sensor, optimized for high sensitivity and dynamic range. The camera can record 4K video at up to 120 fps with 10-bit 4:2:2 color sampling, providing superb video quality and flexibility in post-production. Its advanced autofocus system and in-body image stabilization ensure sharp and steady footage, making it a top choice for videographers and filmmakers.",
            specifications: {
              "Sensor": "12.1 MP full-frame",
              "ISO Range": "80-102400 (expandable to 40-409600)",
              "Autofocus Points": "759 phase-detection points",
              "Continuous Shooting Speed": "10 fps",
              "Video Resolution": "4K (3840 x 2160) at 120 fps",
              "Connectivity": "Wi-Fi, Bluetooth, USB-C",
              "Weight": "699g (body only)"
            }
        },
        {
          id: 6,
          title: "Sony A7 III",
          image: require("../assets/equipment/cameras/sony_a7_3.webp"),
          category: "Camera",
          description:
            "The Sony A7 III is a highly versatile full-frame mirrorless camera that offers a 24.2 MP sensor, providing excellent image quality and performance. It features 5-axis in-body stabilization, which helps in capturing sharp images and stable videos. The camera's fast and accurate autofocus system with 693 phase-detection points covers 93% of the frame, making it ideal for action photography. It also supports 4K video recording and has a robust build, making it suitable for various shooting conditions.",
            specifications: {
              "Sensor": "24.2 MP full-frame",
              "ISO Range": "100-51200 (expandable to 50-204800)",
              "Autofocus Points": "693 phase-detection points",
              "Continuous Shooting Speed": "10 fps",
              "Video Resolution": "4K (3840 x 2160)",
              "Connectivity": "Wi-Fi, Bluetooth, USB-C",
              "Weight": "650g (body only)"
            }
        },
        {
          id: 7,
          title: "Sony A7 R III",
          image: require("../assets/equipment/cameras/sony_a7_r3.webp"),
          category: "Camera",
          description:
            "The Sony A7R III is a high-resolution mirrorless camera that excels in both stills and video. It features a 42.4 MP full-frame sensor, delivering stunning detail and dynamic range. The camera offers 10 fps continuous shooting with full AF/AE tracking, making it perfect for fast-paced photography. It also supports 4K HDR video recording and includes 5-axis in-body stabilization, ensuring high-quality, stable footage. Its advanced autofocus system with Eye AF ensures sharp and precise focus.",
            specifications: {
              "Sensor": "42.4 MP full-frame",
              "ISO Range": "100-32000 (expandable to 50-102400)",
              "Autofocus Points": "399 phase-detection points",
              "Continuous Shooting Speed": "10 fps",
              "Video Resolution": "4K HDR",
              "Connectivity": "Wi-Fi, Bluetooth",
              "Weight": "657g (body only)"
            }
        },
        {
          id: 8,
          title: "Sony A7R IV",
          image: require("../assets/equipment/cameras/sony_a7_r4.webp"),
          category: "Camera",
          description:
            "The Sony A7R IV is a groundbreaking camera with a 61 MP full-frame sensor, providing unmatched resolution and detail. It features real-time Eye AF for both humans and animals, ensuring precise focus in every shot. The camera supports 4K video recording and includes advanced image stabilization, making it a versatile tool for both photography and videography. Its high-resolution electronic viewfinder and robust build make it suitable for professional use in various shooting environments.",
            specifications: {
              "Sensor": "61 MP full-frame",
              "ISO Range": "100-32000 (expandable to 50-102400)",
              "Autofocus Points": "567 phase-detection points",
              "Continuous Shooting Speed": "10 fps",
              "Video Resolution": "4K",
              "Connectivity": "Wi-Fi, Bluetooth",
              "Weight": "665g (body only)"
            }
        },
        {
          id: 9,
          title: "Sony A7 R V",
          image: require("../assets/equipment/cameras/sony_a7_r5.webp"),
          category: "Camera",
          description:
            "The Sony A7R V combines a 61 MP sensor with advanced AI-driven autofocus, providing exceptional image quality and performance. It features improved stabilization and supports 8K video recording, making it a powerful tool for both photographers and videographers. The camera's fast and precise autofocus system, along with real-time tracking and Eye AF, ensures sharp focus in all conditions. Its robust build and high-resolution electronic viewfinder make it ideal for professional use.",
            specifications: {
              "Sensor": "61 MP full-frame",
              "ISO Range": "100-32000 (expandable to 50-102400)",
              "Autofocus Points": "759 phase-detection points",
              "Continuous Shooting Speed": "10 fps",
              "Video Resolution": "8K",
              "Connectivity": "Wi-Fi, Bluetooth",
              "Weight": "723g (body only)"
            }
        },
        {
          id: 10,
          title: "Sony FX3 Cinema Line",
          image: require("../assets/equipment/cameras/sony_fx3.webp"),
          category: "Camera",
          description:
            "The Sony FX3 is a compact cinema camera designed for professional filmmakers. It offers 4K video recording with full-frame sensor performance, dual native ISO, and a robust build. The camera includes advanced autofocus features and in-body image stabilization, ensuring sharp and steady footage. Its compact size and lightweight design make it ideal for handheld shooting and gimbal work, providing versatility for various filming scenarios.",
            specifications: {
              "Sensor": "12.1 MP full-frame",
              "ISO Range": "80-102400 (expandable to 409600)",
              "Autofocus Points": "627 phase-detection points",
              "Continuous Shooting Speed": "10 fps",
              "Video Resolution": "4K at 120 fps",
              "Connectivity": "Wi-Fi, Bluetooth",
              "Weight": "715g (body only)"
            }
        },
        {
          id: 11,
          title: "Sony FX 6 Cinema Line",
          image: require("../assets/equipment/cameras/sony_fx6.webp"),
          category: "Camera",
          description:
            "The Sony FX6 is a versatile cinema camera with a 10.2 MP full-frame sensor, offering high dynamic range and excellent low-light performance. It supports 4K video recording at up to 120 fps, providing high-quality footage with slow-motion capabilities. The camera features advanced autofocus with real-time tracking and Eye AF, ensuring sharp focus in all conditions. Its lightweight and compact design make it suitable for handheld shooting and on-the-go filmmaking.",
            specifications: {
              "Sensor": "10.2 MP full-frame",
              "ISO Range": "320-102400 (expandable to 409600)",
              "Autofocus Points": "627 phase-detection points",
              "Continuous Shooting Speed": "10 fps",
              "Video Resolution": "4K at 120 fps",
              "Connectivity": "Wi-Fi, Bluetooth",
              "Weight": "890g (body only)"
            }
        },
        {
          id: 12,
          title: "Sony FX 9 6K",
          image: require("../assets/equipment/cameras/Sony_fx9.webp"),
          category: "Camera",
          description:
            "The Sony FX9 is a powerful cinema camera with a 6K full-frame sensor, offering high-resolution video and advanced color science. It features dual base ISO for excellent low-light performance and dynamic range. The camera includes advanced autofocus with real-time tracking and Eye AF, ensuring sharp focus in all conditions. Its robust build and professional-grade audio inputs make it ideal for professional filmmakers and videographers.",
            specifications: {
              "Sensor": "19 MP full-frame (6K)",
              "ISO Range": "800-12800 (dual base ISO)",
              "Autofocus Points": "627 phase-detection points",
              "Continuous Shooting Speed": "10 fps",
              "Video Resolution": "6K at 30 fps",
              "Connectivity": "Wi-Fi, Ethernet",
              "Weight": "2.35 kg (body only)"
            }
        },
        {
          id: 13,
          title: "Sony FS7 Mark II",
          image: require("../assets/equipment/cameras/sony_fs7_mark2.webp"),
          category: "Camera",
          description:
            "The Sony FS7 Mark II is a versatile camera designed for documentary and film production. It offers 4K recording with a Super 35 sensor, providing high-quality footage with excellent detail and dynamic range. The camera features an electronic variable ND filter, allowing for smooth exposure control in varying lighting conditions. Its ergonomic design and robust build make it suitable for extended shooting sessions and various filming environments.",
            specifications: {
              "Sensor": "Super 35 CMOS",
              "ISO Range": "2000 (base ISO)",
              "Autofocus Points": "120 phase-detection points",
              "Continuous Shooting Speed": "8 fps",
              "Video Resolution": "4K at 60 fps",
              "Connectivity": "Wi-Fi, Ethernet",
              "Weight": "2 kg (body only)"
            }
        },
        {
          id: 14,
          title: "RED Komodo",
          image: require("../assets/equipment/cameras/red_komodo.webp"),
          category: "Camera",
          description:
            "The RED Komodo is a compact cinema camera with a 6K sensor, offering high dynamic range and global shutter technology. It is designed for dynamic shooting environments, providing excellent image quality and flexibility. The camera's compact size and lightweight design make it ideal for handheld shooting and gimbal work. It supports high-resolution video recording and includes professional-grade connectivity options, making it suitable for a wide range of filmmaking applications.",
            specifications: {
              "Sensor": "19.9 MP Super 35",
              "ISO Range": "800 (base ISO)",
              "Autofocus Points": "None (manual focus)",
              "Continuous Shooting Speed": "None (video camera)",
              "Video Resolution": "6K at 40 fps",
              "Connectivity": "Wi-Fi, SDI",
              "Weight": "950g (body only)"
            }
        },
        {
          id: 15,
          title: "Blackmagic Pocket 6K PRO",
          image: require("../assets/equipment/cameras/blackmagic_pocket_6k_pro.webp"),
          category: "Camera",
          description:
            "The Blackmagic Pocket 6K PRO is a versatile camera with a Super 35 sensor and 6K resolution, offering high-quality video with excellent detail and dynamic range. It features built-in ND filters and a bright HDR touchscreen, making it easy to control exposure and monitor footage. The camera includes professional-grade audio inputs and supports RAW recording, providing flexibility in post-production. Its compact size and robust build make it ideal for both studio and on-location shooting.",
            specifications: {
              "Sensor": "Super 35",
              "ISO Range": "400-3200 (dual native ISO)",
              "Autofocus Points": "None (manual focus)",
              "Continuous Shooting Speed": "None (video camera)",
              "Video Resolution": "6K at 50 fps",
              "Connectivity": "Wi-Fi, Bluetooth",
              "Weight": "1.24 kg (body only)"
            }
        },
        {
          id: 16,
          title: "Blackmagic Pocket 6K",
          image: require("../assets/equipment/cameras/blackmagic_pocket_6k.webp"),
          category: "Camera",
          description:
            "The Blackmagic Pocket 6K is a compact powerhouse, offering stunning 6K resolution and a Super 35 sensor. Ideal for filmmakers, it supports high dynamic range and professional audio inputs. With its user-friendly design, it’s perfect for both indie filmmakers and seasoned professionals. The camera’s color science delivers stunning cinematic images.",
            specifications: {
              "Sensor": "Super 35",
              "Resolution": "6144 x 3456 (6K)",
              "Lens Mount": "EF",
              "Dynamic Range": "13 stops",
              "ISO Range": "Native ISO 400 and 3200",
              "Screen": "5-inch touchscreen",
              "Storage": "CFast 2.0, SD UHS-II, and external USB-C",
              "Battery Life": "Approximately 45 minutes",
              "Weight": "0.9 kg"
            }
        },
        {
          id: 17,
          title: "Sony PXW-200",
          image: require("../assets/equipment/cameras/sony_pxw_200.webp"),
          category: "Camera",
          description: "The Sony PXW-200 is a versatile XDCAM camcorder designed for broadcast and professional use. It features three 1/3-inch Exmor CMOS sensors for superb image quality. The PXW-200 supports multiple recording formats and has a 17x zoom lens. It is ideal for event videography and news gathering.",
          specifications: {
            "Sensors": "Three 1/3-inch Exmor CMOS",
            "Resolution": "1920 x 1080 (HD)",
            "Lens": "17x optical zoom",
            "Recording Formats": "XAVC, MPEG HD422, MPEG HD420, DVCAM",
            "Media": "SxS cards",
            "Connectivity": "SDI, HDMI, USB",
            "Weight": "2.7 kg"
          }
        },
        {
          id: 18,
          title: "Sony PMW-300",
          image: require("../assets/equipment/cameras/sony_pmw_300.webp"),
          category: "Camera",
          description: "The Sony PMW-300 is a shoulder-mount camcorder that delivers exceptional HD video quality. It features a 1/2-inch Exmor Full HD 3CMOS sensor and interchangeable lens system. The camera offers high sensitivity and low noise performance. It is perfect for documentary and ENG applications.",
          specifications: {
            "Sensor": "1/2-inch Exmor Full HD 3CMOS",
            "Resolution": "1920 x 1080 (HD)",
            "Lens": "Interchangeable",
            "Dynamic Range": "Wide",
            "Recording Formats": "XAVC, MPEG HD422, MPEG HD420, DVCAM",
            "Media": "SxS cards",
            "Connectivity": "SDI, HDMI, USB",
            "Weight": "3.9 kg"
          }
        },
        {
          id: 19,
          title: "Insta 360 X3",
          image: require("../assets/equipment/cameras/insta_360_x3.webp"),
          category: "Camera",
          description: "The Insta 360 X3 is a cutting-edge 360-degree camera, perfect for immersive video and photography. It captures high-resolution 5.7K video and features advanced stabilization. The camera is compact and easy to use, making it ideal for action shots and vlogging. It also supports live streaming and various creative modes.",
          specifications: {
            "Sensor": "Dual 1/2.3-inch CMOS",
            "Resolution": "5760 x 2880 (5.7K)",
            "Lens": "Ultra wide-angle",
            "Stabilization": "FlowState Stabilization",
            "Connectivity": "Wi-Fi, Bluetooth",
            "Battery Life": "80 minutes",
            "Weight": "180 g"
          }
        },
        {
          id: 20,
          title: "GoPro Max",
          image: require("../assets/equipment/cameras/gopro_max.webp"),
          category: "Camera",
          description: "The GoPro Max is a versatile 360-degree action camera that excels in capturing immersive footage. It offers 5.6K resolution and excellent stabilization. The Max can function as both a traditional HERO camera and a 360 camera. Its waterproof design and rugged build make it ideal for adventurous filming.",
          specifications: {
            "Sensor": "Dual 1/2.3-inch CMOS",
            "Resolution": "4992 x 2496 (5.6K)",
            "Lens": "Ultra wide-angle",
            "Stabilization": "Max HyperSmooth",
            "Connectivity": "Wi-Fi, Bluetooth",
            "Battery Life": "108 minutes",
            "Weight": "154 g"
          }
        },
        {
          id: 21,
          title: "GoPro Hero 9 Black",
          image: require("../assets/equipment/cameras/gopro_hero9_black.webp"),
          category: "Camera",
          description: "The GoPro Hero 9 Black is a feature-packed action camera with a 23.6MP sensor for stunning 5K video. It includes a front-facing display for easy framing of selfies and vlogs. The Hero 9 offers excellent stabilization and various creative shooting modes. Its rugged, waterproof design ensures it can handle any adventure.",
          specifications: {
            "Sensor": "23.6 MP",
            "Resolution": "5120 x 2880 (5K)",
            "Stabilization": "HyperSmooth 3.0",
            "Display": "2.27-inch rear, 1.4-inch front",
            "Waterproof": "Up to 10 meters",
            "Battery Life": "1-2 hours",
            "Weight": "158 g"
          }
        },
        {
          id: 22,
          title: "GoPro Hero 10 Black",
          image: require("../assets/equipment/cameras/gopro_hero10_black.webp"),
          category: "Camera",
          description: "The GoPro Hero 10 Black pushes the boundaries with its powerful GP2 processor and 23MP sensor. It records 5.3K video at double the frame rate for smoother footage. Enhanced stabilization and improved low-light performance make it a top choice for action enthusiasts. It’s rugged, waterproof, and packed with creative features.",
          specifications: {
            "Processor": "GP2",
            "Sensor": "23 MP",
            "Resolution": "5312 x 2988 (5.3K)",
            "Stabilization": "HyperSmooth 4.0",
            "Display": "2.27-inch rear, 1.4-inch front",
            "Waterproof": "Up to 10 meters",
            "Battery Life": "1-2 hours",
            "Weight": "153 g"
          }
        },
        {
          id: 23,
          title: "GoPro Hero 11 Black",
          image: require("../assets/equipment/cameras/gopro_hero11_black.webp"),
          category: "Camera",
          description: "The GoPro Hero 11 Black continues to lead the action camera market with its advanced features. It offers 5.3K video and HyperSmooth stabilization for ultra-smooth footage. The camera is waterproof and built to withstand harsh environments. New shooting modes and enhanced performance make it perfect for all adventures.",
          specifications: {
            "Sensor": "23 MP",
            "Resolution": "5312 x 2988 (5.3K)",
            "Stabilization": "HyperSmooth 5.0",
            "Display": "2.27-inch rear, 1.4-inch front",
            "Waterproof": "Up to 10 meters",
            "Battery Life": "1-2 hours",
            "Weight": "153 g"
          }
        },
        {
          id: 24,
          title: "GoPro Hero 12 Black",
          image: require("../assets/equipment/cameras/gopro_hero12_black.webp"),
          category: "Camera",
          description: "The GoPro Hero 12 Black is the latest in GoPro's lineup, featuring enhanced image quality and stabilization. It records stunning 5.3K video with improved low-light performance. The camera is rugged, waterproof, and ideal for capturing extreme sports. New features and modes provide endless creative possibilities.",
          specifications: {
            "Sensor": "23 MP",
            "Resolution": "5312 x 2988 (5.3K)",
            "Stabilization": "HyperSmooth 6.0",
            "Display": "2.27-inch rear, 1.4-inch front",
            "Waterproof": "Up to 10 meters",
            "Battery Life": "1-2 hours",
            "Weight": "153 g"
          }
        },
        {
          id: 136,
          title: "Canon EOS R5 Mark II",
          image: require("../assets/equipment/cameras/canon_r5_mark2.webp"),
          category: "Camera",
          description: "The Canon EOS R5 Mark II is a cutting-edge full-frame mirrorless camera, designed for professionals and advanced enthusiasts. Featuring enhanced image quality, incredible speed, and versatile video capabilities, this camera is a powerhouse for both photo and video production. Its robust build, advanced autofocus system, and improved stabilization ensure exceptional performance in any shooting environment.",
          specifications: {
            "Sensor": "45MP Full-Frame CMOS",
            "Image Processor": "DIGIC X",
            "ISO Range": "100-51200 (Expandable to 102400)",
            "Autofocus": "Dual Pixel CMOS AF II with Eye Detection",
            "Continuous Shooting": "20 fps (Electronic), 12 fps (Mechanical)",
            "Video Recording": "8K up to 60fps, 4K up to 120fps",
            "Image Stabilization": "In-Body 5-Axis IS",
            "Viewfinder": "5.76M-dot OLED EVF",
            "LCD": "3.2-inch Vari-Angle Touchscreen",
            "Connectivity": "Wi-Fi, Bluetooth, USB-C",
            "Weight": "735 g (with battery and card)"
          }
        },
      ],
    },
    {
      category: "Lens",
      items: [
        {
          id: 25,
          title: "Sony 16-35mm G master (f2.8)",
          image: require("../assets/equipment/lens/sony_16_35mm_gmf2.8.webp"),
          category: "Lens",
          description: "The Sony 16-35mm G Master (f2.8) is a versatile wide-angle zoom lens, ideal for landscapes, architecture, and video. Its constant f2.8 aperture ensures excellent performance in low light. With advanced optics and a durable build, it delivers sharp, high-contrast images. Perfect for both professional photographers and videographers.",
          specifications: {
            "Focal Length": "16-35mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.28 m",
            "Image Stabilization": "No",
            "Weight": "680 g"
          }
        },
        {
          id: 26,
          title: "Sony 24-70mm G master (f2.8)",
          image: require("../assets/equipment/lens/sony_24_70mm_gmf2.8.webp"),
          category: "Lens",
          description: "The Sony 24-70mm G Master (f2.8) is a premium standard zoom lens known for its exceptional sharpness and clarity. The constant f2.8 aperture makes it suitable for a wide range of lighting conditions. It features advanced autofocus and a robust construction. Ideal for portrait, wedding, and event photography.",
          specifications: {
            "Focal Length": "24-70mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.38 m",
            "Image Stabilization": "No",
            "Weight": "886 g"
          }
        },
        {
          id: 27,
          title: "Sony 24-70mm G Master II (f2.8)",
          image: require("../assets/equipment/lens/sony_24_70mm_gmII_f2.8.webp"),
          category: "Lens",
          description: "The Sony 24-70mm G Master II (f2.8) is an upgraded version offering improved optics and faster autofocus. It maintains a constant f2.8 aperture throughout the zoom range. The lens is lighter and more compact, enhancing portability. Perfect for versatile shooting, from portraits to landscapes.",
          specifications: {
            "Focal Length": "24-70mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.21-0.30 m",
            "Image Stabilization": "No",
            "Weight": "695 g"
          }
        },
        {
          id: 28,
          title: "Sony 70-200mm G Master (f2.8)",
          image: require("../assets/equipment/lens/sony_70_200mm_gmaster.webp"),
          category: "Lens",
          description: "The Sony 70-200mm G Master (f2.8) is a high-performance telephoto zoom lens ideal for sports, wildlife, and portrait photography. Its constant f2.8 aperture provides excellent low-light performance. With advanced stabilization and fast autofocus, it delivers sharp, detailed images. A must-have for professional photographers.",
          specifications: {
            "Focal Length": "70-200mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.96 m",
            "Image Stabilization": "Yes",
            "Weight": "1480 g"
          }
        },
        {
          id: 29,
          title: "Sony 70-200mm G MasterII(f2.8)",
          image: require("../assets/equipment/lens/sony_70_200mm_gm2.webp"),
          category: "Lens",
          description: "The Sony 70-200mm G Master II (f2.8) is a refined version with enhanced optics and reduced weight. It offers a constant f2.8 aperture and advanced image stabilization. The lens is designed for high-speed shooting and delivers stunning sharpness. Ideal for capturing distant subjects with precision.",
          specifications: {
            "Focal Length": "70-200mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.4-0.82 m",
            "Image Stabilization": "Yes",
            "Weight": "1045 g"
          }
        },
        {
          id: 30,
          title: "Sony 200-600 mm (f5.6–6.3)",
          image: require("../assets/equipment/lens/sony_200_600mm.webp"),
          category: "Lens",
          description: "The Sony 200-600mm (f5.6–6.3) is a super-telephoto zoom lens perfect for wildlife and sports photography. Its wide focal range allows for capturing distant subjects with ease. The lens features advanced stabilization and fast autofocus. Rugged and durable, it performs well in various environmental conditions.",
          specifications: {
            "Focal Length": "200-600mm",
            "Aperture": "f/5.6-6.3",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "2.4 m",
            "Image Stabilization": "Yes",
            "Weight": "2115 g"
          }
        },
        {
          id: 31,
          title: "Sony 14mm G Master (f1.8)",
          image: require("../assets/equipment/lens/sony_14mm_gmaster.webp"),
          category: "Lens",
          description: "The Sony 14mm G Master (f1.8) is an ultra-wide-angle prime lens known for its exceptional sharpness and speed. The f1.8 aperture makes it excellent for low-light photography and astrophotography. Compact and lightweight, it is perfect for landscapes and architectural shots. Delivers stunning clarity and minimal distortion.",
          specifications: {
            "Focal Length": "14mm",
            "Aperture": "f/1.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.25 m",
            "Image Stabilization": "No",
            "Weight": "460 g"
          }
        },
        {
          id: 32,
          title: "Sony 20mm prime lens (f1.8)",
          image: require("../assets/equipment/lens/sony_20mm_primelens.webp"),
          category: "Lens",
          description: "The Sony 20mm prime lens (f1.8) is a versatile wide-angle lens ideal for landscapes, interiors, and video. Its fast f1.8 aperture offers excellent low-light performance and beautiful bokeh. The lens is compact, lightweight, and features advanced optics for sharp, high-contrast images. Perfect for creative photography.",
          specifications: {
            "Focal Length": "20mm",
            "Aperture": "f/1.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.19 m",
            "Image Stabilization": "No",
            "Weight": "373 g"
          }
        },
        {
          id: 33,
          title: "Sony 24mm G Master (f1.4)",
          image: require("../assets/equipment/lens/sony_24mm_gmaster.webp"),
          category: "Lens",
          description: "The Sony 24mm G Master (f1.4) is a fast wide-angle prime lens renowned for its sharpness and creamy bokeh. The f1.4 aperture excels in low-light conditions and offers beautiful background separation. It is compact and lightweight, making it ideal for travel and street photography. Delivers stunning image quality.",
          specifications: {
            "Focal Length": "24mm",
            "Aperture": "f/1.4",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.24 m",
            "Image Stabilization": "No",
            "Weight": "445 g"
          }
        },
        {
          id: 34,
          title: "Sony 35mm G Master (f1.4)",
          image: require("../assets/equipment/lens/sony_35mm_gmaster.webp"),
          category: "Lens",
          description: "The Sony 35mm G Master (f1.4) is a classic prime lens known for its versatile focal length and superb image quality. The fast f1.4 aperture provides excellent low-light performance and beautiful bokeh. Ideal for portraits, street photography, and video. It combines advanced optics with a compact, robust design.",
          specifications: {
            "Focal Length": "35mm",
            "Aperture": "f/1.4",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.27 m",
            "Image Stabilization": "No",
            "Weight": "524 g"
          }
        },
        {
          id: 35,
          title: "Sony 50mm G Master (f1.2)",
          image: require("../assets/equipment/lens/sony_50mm_gm.webp"),
          category: "Lens",
          description: "The Sony 50mm G Master (f1.2) is a premium prime lens offering exceptional sharpness and stunning bokeh. The ultra-fast f1.2 aperture allows for incredible low-light performance and creative depth of field control. Ideal for portrait and event photography. Built to professional standards with advanced optics.",
          specifications: {
            "Focal Length": "50mm",
            "Aperture": "f/1.2",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.4 m",
            "Image Stabilization": "No",
            "Weight": "778 g"
          }
        },
        {
          id: 36,
          title: "Sony 85mm G Master (f1.4)",
          image: require("../assets/equipment/lens/sony_85mm_gm.webp"),
          category: "Lens",
          description: "The Sony 85mm G Master (f1.4) is a portrait lens designed for stunning sharpness and beautiful background blur. The fast f1.4 aperture is perfect for low-light situations and creative photography. Advanced optics ensure excellent image quality. Ideal for portraiture, fashion, and wedding photography.",
          specifications: {
            "Focal Length": "85mm",
            "Aperture": "f/1.4",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.85 m",
            "Image Stabilization": "No",
            "Weight": "820 g"
          }
        },
        {
          id: 37,
          title: "Sony 135mm G Master",
          image: require("../assets/equipment/lens/sony_135mm_gm.webp"),
          category: "Lens",
          description: "The Sony 135mm G Master is a high-performance prime lens known for its exceptional sharpness and smooth bokeh. The f1.8 aperture offers excellent low-light performance and shallow depth of field. Ideal for portrait and event photography. The lens features advanced optics and a durable, weather-sealed design.",
          specifications: {
            "Focal Length": "135mm",
            "Aperture": "f/1.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.7 m",
            "Image Stabilization": "No",
            "Weight": "950 g"
          }
        },
        {
          id: 38,
          title: "Sony 50mm (f1.8)",
          image: require("../assets/equipment/lens/sony_50mm_f1.8.webp"),
          category: "Lens",
          description: "The Sony 50mm (f1.8) is an affordable prime lens that offers excellent image quality and performance. The fast f1.8 aperture allows for great low-light shooting and attractive background blur. Compact and lightweight, it is perfect for everyday photography. Ideal for portraits, street, and travel photography.",
          specifications: {
            "Focal Length": "50mm",
            "Aperture": "f/1.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.45 m",
            "Image Stabilization": "No",
            "Weight": "186 g"
          }
        },
        {
          id: 39,
          title: "Sony 28-70mm Kit lens",
          image: require("../assets/equipment/lens/sony_28-70mm_kitlens.webp"),
          category: "Lens",
          description: "The Sony 28-70mm Kit lens is a versatile zoom lens that covers a wide range of focal lengths. Its compact and lightweight design makes it ideal for travel and everyday use. The lens features optical stabilization and reliable autofocus. Perfect for capturing everything from landscapes to portraits.",
          specifications: {
            "Focal Length": "28-70mm",
            "Aperture": "f/3.5-5.6",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.3 m (Wide), 0.45 m (Tele)",
            "Image Stabilization": "Yes",
            "Weight": "295 g"
          }
        },
        {
          id: 40,
          title: "Sigma 14mm (f1.8)",
          image: require("../assets/equipment/lens/sigma_14mm_f1.8.webp"),
          category: "Lens",
          description: "The Sigma 14mm (f1.8) is an ultra-wide-angle prime lens known for its exceptional brightness and sharpness. The f1.8 aperture makes it perfect for low-light photography and astrophotography. It features advanced optics to minimize distortion and deliver clear, high-contrast images. Ideal for landscapes and architecture.",
          specifications: {
            "Focal Length": "14mm",
            "Aperture": "f/1.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.27 m",
            "Image Stabilization": "No",
            "Weight": "1.13 kg"
          }
        },
        {
          id: 41,
          title: "Sigma 35mm (f1.4) E-Mount",
          image: require("../assets/equipment/lens/sigma_35mm_emount.webp"),
          category: "Lens",
          description: "The Sigma 35mm (f1.4) E-Mount lens is a fast prime lens known for its excellent image quality and low-light performance. The wide f1.4 aperture provides beautiful bokeh and sharpness, making it ideal for portraits, street photography, and low-light conditions. It features advanced optics and a robust build, suitable for professional use.",
          specifications: {
            "Focal Length": "35mm",
            "Aperture": "f/1.4",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.30 m",
            "Image Stabilization": "No",
            "Weight": "665 g"
          }

        },
        {
          id: 42,
          title: "Sigma 24mm(f1.4) E-Mount",
          image: require("../assets/equipment/lens/sigma_24mm_emount.webp"),
          category: "Lens",
          description: "The Sigma 24mm (f1.4) E-Mount lens is a wide-angle prime known for its fast aperture and superb optical performance. The f1.4 aperture ensures excellent low-light capability and shallow depth of field. It is suitable for landscape, architecture, and astrophotography. The lens combines high image quality with a durable build.",
          specifications: {
            "Focal Length": "24mm",
            "Aperture": "f/1.4",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.25 m",
            "Image Stabilization": "No",
            "Weight": "665 g"
          }
        },
        {
          id: 43,
          title: "Sigma 50mm (f1.4) E-Mount",
          image: require("../assets/equipment/lens/sigma_50mm_emount.webp"),
          category: "Lens",
          description: "The Sigma 50mm (f1.4) E-Mount lens is a versatile prime lens known for its sharpness and clarity. The fast f1.4 aperture provides exceptional low-light performance and beautiful bokeh, ideal for portraits and everyday photography. It features advanced optics and a rugged construction, suitable for professional photographers.",
          specifications: {
            "Focal Length": "50mm",
            "Aperture": "f/1.4",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.40 m",
            "Image Stabilization": "No",
            "Weight": "815 g"
          }
        },
        {
          id: 44,
          title: "Sigma 85mm (f1.4) E- Mount",
          image: require("../assets/equipment/lens/sigma_85mm_emount.webp"),
          category: "Lens",
          description: "The Sigma 85mm (f1.4) E-Mount lens is a premium portrait lens known for its outstanding sharpness and beautiful bokeh. The fast f1.4 aperture excels in low-light conditions and provides excellent subject isolation. It features advanced optics with special glass elements for superior image quality. Perfect for professional portraits and studio work.",
          specifications: {
            "Focal Length": "85mm",
            "Aperture": "f/1.4",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.85 m",
            "Image Stabilization": "No",
            "Weight": "1130 g"
          }
        },
        {
          id: 45,
          title: "Laowa Probe Macro Lens f14",
          image: require("../assets/equipment/lens/laowa_probe_macro.webp"),
          category: "Lens",
          description: "The Laowa Probe Macro Lens f14 is a unique macro lens designed for creative macro photography. Its long, tubular design allows for shooting subjects from unique perspectives. With a wide f14 aperture and high magnification, it captures intricate details and textures. Ideal for macro enthusiasts and filmmakers looking for innovative shots.",
          specifications: {
            "Focal Length": "24mm",
            "Aperture": "f/14",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "2 cm",
            "Image Stabilization": "No",
            "Weight": "474 g"
          }
        },
        {
          id: 46,
          title: "Batis 18mm f2.8",
          image: require("../assets/equipment/lens/batis_18mm.webp"),
          category: "Lens",
          description: "The Batis 18mm f2.8 is a wide-angle prime lens known for its compact design and excellent optical performance. It offers a wide f2.8 aperture and advanced Zeiss optics for sharpness and clarity. The lens features autofocus and is dust and weather-sealed, making it suitable for outdoor and landscape photography.",
          specifications: {
            "Focal Length": "18mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.25 m",
            "Image Stabilization": "No",
            "Weight": "330 g"
          }
        },
        {
          id: 47,
          title: "Batis 25mm f2.8",
          image: require("../assets/equipment/lens/batis_25mm.webp"),
          category: "Lens",
          description: "The Batis 25mm f2.8 is a wide-angle prime lens known for its compact size and exceptional image quality. It features a fast f2.8 aperture and Zeiss optics for sharpness and contrast. The lens includes autofocus and is designed with weather-sealing for reliable performance in various conditions. Ideal for landscapes and architectural photography.",
          specifications: {
            "Focal Length": "25mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.2 m",
            "Image Stabilization": "No",
            "Weight": "335 g"
          }
        },
        {
          id: 48,
          title: "Batis 40mm f2.8",
          image: require("../assets/equipment/lens/batis_40mm.webp"),
          category: "Lens",
          description: "The Batis 40mm f2.8 is a versatile prime lens known for its compactness and high optical performance. It offers a moderate f2.8 aperture and Zeiss optics for sharpness and clarity. The lens features autofocus and is weather-sealed, suitable for outdoor photography and everyday use.",
          specifications: {
            "Focal Length": "40mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.24 m",
            "Image Stabilization": "No",
            "Weight": "361 g"
          }
        },
        {
          id: 49,
          title: "Batis 85mm f2.8",
          image: require("../assets/equipment/lens/batis_85mm.webp"),
          category: "Lens",
          description: "The Batis 85mm f2.8 is a telephoto prime lens known for its lightweight design and excellent optical quality. It features a fast f2.8 aperture and Zeiss optics for sharpness and contrast. The lens includes autofocus and is dust and weather-sealed, making it ideal for portraits and low-light photography.",
          specifications: {
            "Focal Length": "85mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.80 m",
            "Image Stabilization": "No",
            "Weight": "452 g"
          }
        },
        {
          id: 50,
          title: "Samyang 35mm (f1.8) E-Mount",
          image: require("../assets/equipment/lens/samyang_35mm.webp"),
          category: "Lens",
           description: "The Samyang 35mm (f1.8) E-Mount lens is a compact prime known for its sharpness and versatility. The fast f1.8 aperture provides excellent low-light performance and shallow depth of field. It features advanced optics and a lightweight design, making it ideal for street photography, portraits, and everyday use.",
           specifications: {
            "Focal Length": "35mm",
            "Aperture": "f/1.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.29 m",
            "Image Stabilization": "No",
            "Weight": "210 g"
          }
        },
        {
          id: 51,
          title: "Sony 90mm f2.8 Macro",
          image: require("../assets/equipment/lens/sony_90mm_f2.8_macro.webp"),
          category: "Lens",
          description: "The Sony 90mm f2.8 Macro lens is designed for close-up photography, offering true 1:1 magnification with exceptional sharpness and clarity. It features a versatile f2.8 aperture that allows for beautiful background bokeh and low-light performance. Ideal for macro enthusiasts and professionals seeking detailed close-up shots of insects, flowers, and small objects.",
          specifications: {
            "Focal Length": "90mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Sony E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.28 m",
            "Magnification": "1:1",
            "Image Stabilization": "Yes",
            "Weight": "602 g"
          }
        },
        {
          id: 52,
          title: "Canon RF 24-70mm f2.8",
          image: require("../assets/equipment/lens/canon_24_70_f2.8.webp"),
          category: "Lens",
          description: "The Canon RF 24-70mm f2.8 lens is a versatile zoom lens known for its excellent optical performance and constant f2.8 aperture throughout the zoom range. It offers sharpness, clarity, and fast autofocus, making it suitable for a wide range of photography genres including portraits, landscapes, and events. The lens is weather-sealed for reliability in various conditions.",
          specifications: {
            "Focal Length": "24-70mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Canon RF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.21 m",
            "Image Stabilization": "Yes",
            "Weight": "900 g"
          }
        },
        {
          id: 53,
          title: "Canon RF 15-35mm f2.8",
          image: require("../assets/equipment/lens/canon_15_35_f2.8.webp"),
          category: "Lens",
          description: "The Canon RF 15-35mm f2.8 lens is a wide-angle zoom designed for Canon's full-frame RF mount cameras. It features a bright f2.8 aperture across the zoom range, ensuring excellent low-light performance and depth of field control. Ideal for landscape, architecture, and interior photography, this lens delivers sharpness, contrast, and minimal distortion.",
          specifications: {
            "Focal Length": "15-35mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Canon RF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.28 m",
            "Image Stabilization": "Yes",
            "Weight": "840 g"
          }
        },
        {
          id: 54,
          title: "Canon RF 70-200mm f2.8",
          image: require("../assets/equipment/lens/canon_70_200mm.webp"),
          category: "Lens",
          description: "The Canon RF 70-200mm f2.8 lens is a telephoto zoom offering exceptional optical performance and a bright f2.8 aperture. It provides versatile focal lengths for portraits, sports, and wildlife photography with sharpness and clarity throughout the zoom range. The lens features fast autofocus and is weather-sealed, making it suitable for professional use in challenging conditions.",
          specifications: {
            "Focal Length": "70-200mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Canon RF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.70 m",
            "Image Stabilization": "Yes",
            "Weight": "1070 g"
          }
        },
        {
          id: 55,
          title: "Canon RF 50mm f1.8",
          image: require("../assets/equipment/lens/canon_rf_50mm.webp"),
          category: "Lens",
          description: "The Canon RF 50mm f1.8 lens is a compact prime offering excellent image quality and a wide f1.8 aperture for beautiful background blur and low-light capability. It is ideal for portraits, street photography, and everyday use, delivering sharpness and clarity with minimal distortion. The lens is lightweight and features STM motor technology for smooth and quiet autofocus.",
          specifications: {
            "Focal Length": "50mm",
            "Aperture": "f/1.8",
            "Lens Mount": "Canon RF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.30 m",
            "Image Stabilization": "No",
            "Weight": "160 g"
          }
        },
        {
          id: 56,
          title: "Canon RF 35mm f1.8",
          image: require("../assets/equipment/lens/canon_rf_35mm.webp"),
          category: "Lens",
          description: "The Canon RF 35mm f1.8 lens is a versatile prime known for its compact size, sharpness, and wide f1.8 aperture. It excels in low-light conditions and offers a natural perspective, making it ideal for street photography, landscapes, and portraits. The lens features STM motor technology for fast and quiet autofocus, suitable for both photo and video applications.",
          specifications: {
            "Focal Length": "35mm",
            "Aperture": "f/1.8",
            "Lens Mount": "Canon RF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.17 m",
            "Image Stabilization": "Yes",
            "Weight": "305 g"
          }

        },
        {
          id: 57,
          title: "Canon RF 85mm f1.8",
          image: require("../assets/equipment/lens/canon_rf85mm.webp"),
          category: "Lens",
          description: "The Canon RF 85mm f1.8 lens is a portrait prime known for its sharpness, clarity, and beautiful bokeh. The wide f1.8 aperture excels in low-light conditions and provides excellent subject isolation. Ideal for portraits and creative photography, this lens features STM motor technology for smooth and quiet autofocus, making it suitable for both photo and video use.",
          specifications: {
            "Focal Length": "85mm",
            "Aperture": "f/1.8",
            "Lens Mount": "Canon RF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.85 m",
            "Image Stabilization": "No",
            "Weight": "500 g"
          }
        },
        {
          id: 133,
          title: "Canon RF 50mm f1.2",
          image: require("../assets/equipment/lens/canon_rf_50mm_1.2.webp"),
          category: "Lens",
          description: "The Canon RF 50mm f1.2 lens is a premium standard prime lens renowned for its stunning sharpness, excellent low-light performance, and creamy bokeh. With an ultra-wide f/1.2 aperture, this lens delivers unparalleled depth-of-field control, making it ideal for portraits, street photography, and artistic projects. It features advanced optical design and fast, precise autofocus, making it a perfect choice for professional photographers.",
          specifications: {
            "Focal Length": "50mm",
            "Aperture": "f/1.2",
            "Lens Mount": "Canon RF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.4 m",
            "Image Stabilization": "No",
            "Weight": "950 g"
          }
        },
        {
          id: 58,
          title: "Canon EF 100 mm Macro",
          image: require("../assets/equipment/lens/canon_ef_100mm_macro.webp"),
          category: "Lens",
          description: "The Canon EF 100mm Macro lens is designed for close-up photography with true 1:1 magnification. It offers exceptional sharpness and detail, making it ideal for capturing intricate subjects like insects, flowers, and textures. The lens features a fast aperture for beautiful background blur and is suitable for macro enthusiasts and professionals alike.",
          specifications: {
            "Focal Length": "100mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Canon EF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.30 m",
            "Magnification": "1:1",
            "Image Stabilization": "Yes",
            "Weight": "625 g"
          }
        },
        {
          id: 59,
          title: "Canon EF 16-35mm 2.8 IS II",
          image: require("../assets/equipment/lens/canon_ef_1635mm_is2.webp"),
          category: "Lens",
          description: "The Canon EF 16-35mm f2.8 IS II lens is a versatile wide-angle zoom known for its optical excellence and constant f2.8 aperture. It provides sharpness, clarity, and image stabilization, making it ideal for landscapes, architecture, and interiors. The lens features fast autofocus and weather-sealing, ensuring reliability in various shooting conditions.",
          specifications: {
            "Focal Length": "16-35mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Canon EF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.28 m",
            "Image Stabilization": "Yes",
            "Weight": "640 g"
          }
        },
        {
          id: 60,
          title: "Canon EF 16-35mm 2.8 IS III",
          image: require("../assets/equipment/lens/canon_ef_1635mm_is3.webp"),
          category: "Lens",
          description: "The Canon EF 16-35mm f2.8 IS III lens builds on its predecessor's reputation with enhanced optics and image stabilization. It offers a versatile range for wide-angle shooting, delivering sharpness, clarity, and minimal distortion throughout the zoom range. Ideal for landscapes, architecture, and events, this lens ensures superb image quality and reliability.",
          specifications: {
            "Focal Length": "16-35mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Canon EF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.28 m",
            "Image Stabilization": "Yes",
            "Weight": "790 g"
          }
        },
        {
          id: 61,
          title: "Canon EF 24-70mm 2.8 IS II",
          image: require("../assets/equipment/lens/canon_ef_2470_is2.webp"),
          category: "Lens",
          description: "The Canon EF 24-70mm f2.8 IS II lens is a standard zoom known for its exceptional optical performance and constant f2.8 aperture. It delivers sharpness, clarity, and image stabilization, making it ideal for portraits, weddings, events, and everyday photography. The lens features fast autofocus and weather-sealing, ensuring reliability in various shooting conditions.",
          specifications: {
            "Focal Length": "24-70mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Canon EF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.38 m",
            "Image Stabilization": "Yes",
            "Weight": "805 g"
          }
        },
        {
          id: 62,
          title: "Canon EF 70-200mm 2.8",
          image: require("../assets/equipment/lens/canon_ef_70200mm.webp"),
          category: "Lens",
          description: "The Canon EF 70-200mm f2.8 lens is a versatile telephoto zoom offering excellent optical performance and a bright f2.8 aperture. It provides sharpness, clarity, and image stabilization, making it ideal for sports, wildlife, and portrait photography. The lens features fast autofocus and is weather-sealed for reliable performance in challenging conditions.",
          specifications: {
            "Focal Length": "70-200mm",
            "Aperture": "f/2.8",
            "Lens Mount": "Canon EF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "1.20 m",
            "Image Stabilization": "Yes",
            "Weight": "1480 g"
          }
        },
        {
          id: 63,
          title: "Canon EF 50mm 1.4f",
          image: require("../assets/equipment/lens/canon_ef_50mm.webp"),
          category: "Lens",
          description: "The Canon EF 50mm 1.4f lens is a standard prime known for its sharpness and versatile f1.4 aperture. It excels in low-light conditions and offers beautiful background blur, making it ideal for portraits, street photography, and everyday use. The lens features fast autofocus and is lightweight, perfect for photographers seeking high-quality results.",
          specifications: {
            "Focal Length": "50mm",
            "Aperture": "f/1.4",
            "Lens Mount": "Canon EF",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "0.45 m",
            "Image Stabilization": "No",
            "Weight": "290 g"
          }
        },
        {
          id: 64,
          title: "CP2 Cine Lens Kit",
          image: require("../assets/equipment/lens/cp2_cine_lenskit.webp"),
          category: "Lens",
          description: "The CP2 Cine Lens Kit includes a range of professional cine lenses known for their superior optical quality and robust build. These lenses are designed for cinematic use, offering precise focus control, consistent aperture across the set, and minimal lens breathing. Ideal for filmmakers and cinematographers looking to achieve cinematic excellence.",
          specifications: {
            "Lens Types": "15mm, 18mm, 21mm, 25mm, 28mm, 35mm, 50mm, 85mm, 100mm, 135mm",
            "Aperture": "T2.1 or T2.9 (depending on lens)",
            "Lens Mount": "PL, EF, F, E, MFT",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "Varies by lens",
            "Weight": "Varies by lens"
          }
        },
        {
          id: 65,
          title: "CP3 Cine Lens Kit",
          image: require("../assets/equipment/lens/cp3_cine_lenskit.webp"),
          category: "Lens",
          description: "The CP3 Cine Lens Kit features precision-engineered cine lenses designed for professional filmmaking. These lenses offer high optical performance, consistent aperture, and robust build quality suitable for demanding shooting environments. With advanced features like lens coatings and minimal focus breathing, they are ideal for achieving cinematic results.",
          specifications: {
            "Lens Types": "15mm, 18mm, 21mm, 25mm, 28mm, 35mm, 50mm, 85mm, 100mm, 135mm",
            "Aperture": "T2.1 or T2.9 (depending on lens)",
            "Lens Mount": "PL, EF, F, E, MFT",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "Varies by lens",
            "Weight": "Varies by lens"
          }
        },
        {
          id: 66,
          title: "Sigma Cine Prime lens Kit",
          image: require("../assets/equipment/lens/sigma_cine_prime.webp"),
          category: "Lens",
          description: "The Sigma Cine Prime Lens Kit includes a set of high-performance prime lenses optimized for professional cinematography. These lenses feature exceptional optical quality, precise manual focus, and consistent aperture. Designed for 4K and beyond, they deliver cinematic image quality with minimal distortion and color aberration, perfect for filmmaking.",
          specifications: {
            "Lens Types": "20mm, 24mm, 28mm, 35mm, 40mm, 50mm, 85mm, 105mm, 135mm",
            "Aperture": "T1.5 or T2.0 (depending on lens)",
            "Lens Mount": "PL, EF, E",
            "Format Compatibility": "Full-Frame",
            "Minimum Focus Distance": "Varies by lens",
            "Weight": "Varies by lens"
          }
        },
        {
          id: 124,
          title: "NANO Prime Lens Kit",
          image: require("../assets/equipment/lens/nano-prime-lens-kit.avif"),
          category: "Lens",
          description: "The NANO Prime Lens Kit is a set of lightweight, compact lenses ideal for mobile cinematography and handheld shooting. With superior optical quality and precise manual controls, these lenses are designed to deliver consistent and cinematic results across various filming conditions.",
          specifications: {
            "Lens Types": "18mm, 25mm, 35mm, 50mm, 75mm, 100mm",
            "Aperture": "T1.8",
            "Lens Mount": "PL, E",
            "Format Compatibility": "Super35",
            "Minimum Focus Distance": "Varies by lens",
            "Weight": "Lightweight design (varies by lens)"
          }
        },
        {
          id: 125,
          title: "Cookie SP3 Lens Kit",
          image: require("../assets/equipment/lens/cooki_sp3_lens_kit.webp"),
          category: "Lens",
          description: "The Cookie SP3 Lens Kit provides a versatile range of lenses known for their unique warm tones and beautiful bokeh, perfect for character-driven storytelling. These lenses are built with robust construction to withstand rigorous production environments while maintaining high optical performance.",
          specifications: {
            "Lens Types": "25mm, 32mm, 50mm, 75mm, 100mm",
            "Aperture": "T2.1",
            "Lens Mount": "PL, EF",
            "Format Compatibility": "Full-Frame and Super35",
            "Minimum Focus Distance": "Varies by lens",
            "Weight": "Medium weight design (varies by lens)"
          }
        },
      ],
    },
    {
      category: "Lights",
      items: [
        {
          id: 67,
          title: "Godox Flash V860",
          image: require("../assets/equipment/lights/godox_v860.webp"),
          category: "Lights",
          description: "The Godox Flash V860 is a powerful and versatile TTL flash designed for professional photographers. It features a high-capacity lithium-ion battery, fast recycling time, and wireless TTL functionality. Ideal for both on-camera and off-camera use, it provides consistent lighting and supports high-speed sync (HSS) for capturing fast-moving subjects.",
          specifications: {
            "Power": "76Ws",
            "Guide Number": "60 (m, ISO 100, @ 200mm)",
            "Battery": "Lithium-ion 11.1V/2000mAh",
            "Recycling Time": "1.5 seconds",
            "Flash Duration": "1/300 to 1/20000 second",
            "High-Speed Sync": "Yes",
            "Wireless Range": "100m"
          }
        },
        {
          id: 68,
          title: "AD 200 Flash",
          image: require("../assets/equipment/lights/AD200_flash.webp"),
          category: "Lights",
          description: "The AD 200 Flash from Godox is a portable and powerful flash unit with 200Ws of power. It features a modular design with interchangeable bare bulb and speedlight flash heads, providing flexibility for various lighting setups. The AD 200 supports HSS and TTL modes, making it suitable for both studio and on-location photography.",
          specifications: {
            "Power": "200Ws",
            "Guide Number": "52 (m, ISO 100, with standard head)",
            "Battery": "Lithium-ion 14.4V/2900mAh",
            "Recycling Time": "0.01 to 2.1 seconds",
            "Flash Duration": "1/220 to 1/13000 second",
            "High-Speed Sync": "Yes",
            "Wireless Range": "100m"
          }
        },
        {
          id: 69,
          title: "AD 300 Flash",
          image: require("../assets/equipment/lights/ad300_flash.webp"),
          category: "Lights",
          description: "The AD 300 Flash from Godox is a compact and portable flash unit offering 300Ws of power. It features a stable color temperature, fast recycling time, and compatibility with Godox's wireless X system for remote control. Ideal for portrait, product, and event photography, it provides consistent and reliable lighting performance.",
          specifications: {
            "Power": "300Ws",
            "Guide Number": "60 (m, ISO 100, @ 300mm)",
            "Battery": "Lithium-ion 14.4V/2600mAh",
            "Recycling Time": "0.01 to 1.5 seconds",
            "Flash Duration": "1/220 to 1/10000 second",
            "High-Speed Sync": "Yes",
            "Wireless Range": "100m"
          }
        },
        {
          id: 70,
          title: "AD 400 Flash",
          image: require("../assets/equipment/lights/ad400_flash.webp"),
          category: "Lights",
          description: "The AD 400 Flash from Godox is a versatile and powerful strobe with 400Ws of power. It supports high-speed sync (HSS), TTL, and manual modes, offering flexibility for various lighting setups. The AD 400 features a stable color temperature and fast recycling time, making it ideal for professional studio and on-location photography.",
          specifications: {
            "Power": "400Ws",
            "Guide Number": "72 (m, ISO 100, with standard reflector)",
            "Battery": "Lithium-ion 21.6V/2600mAh",
            "Recycling Time": "0.01 to 1.0 second",
            "Flash Duration": "1/240 to 1/12820 second",
            "High-Speed Sync": "Yes",
            "Wireless Range": "100m"
          }
        },
        {
          id: 71,
          title: "AD 600 Flash2",
          image: require("../assets/equipment/lights/ad600_flash.webp"),
          category: "Lights",
          description: "The AD 600 Flash from Godox is a high-power strobe with 600Ws of power output. It supports TTL, HSS, and manual modes, providing flexibility for different shooting conditions. The AD 600 features a stable color temperature and fast recycling time, making it suitable for portrait, commercial, and fashion photography.",
          specifications: {
            "Power": "600Ws",
            "Guide Number": "87 (m, ISO 100, with standard reflector)",
            "Battery": "Lithium-ion 28.8V/2600mAh",
            "Recycling Time": "0.01 to 2.5 seconds",
            "Flash Duration": "1/220 to 1/10000 second",
            "High-Speed Sync": "Yes",
            "Wireless Range": "100m"
          }
        },
        {
          id: 72,
          title: "Aputure Amaran 200d",
          image: require("../assets/equipment/lights/aputure_amaran_200d.webp"),
          category: "Lights",
          description: "The Aputure Amaran 200d is a daylight-balanced LED light with a high CRI/TLCI rating, ensuring accurate color reproduction. It offers quiet fan cooling, brightness control, and robust build quality suitable for video production. The 200d provides flicker-free illumination and supports wireless control via the Sidus Link app, ideal for film and broadcast lighting.",
          specifications: {
            "Power Output": "200W",
            "Color Temperature": "5600K",
            "CRI/TLCI": "95+/96+",
            "Dimming": "0-100%",
            "Cooling": "Active Cooling",
            "Control": "Wireless (Sidus Link App)",
            "Special Effects": "Yes",
            "Power Supply": "AC Power"
          }
        },
        {
          id: 73,
          title: "Aputure Amaran 200x",
          image: require("../assets/equipment/lights/aputure_amaran_200x.webp"),
          category: "Lights",
          description: "The Aputure Amaran 200x is a bi-color LED light offering adjustable color temperature from 2700K to 6500K. It features a high CRI/TLCI rating for accurate color rendering and supports brightness control with stepless dimming. The 200x includes built-in special effects and is compatible with wireless control via the Sidus Link app, suitable for video and photography.",
          specifications: {
            "Power Output": "200W",
            "Color Temperature": "2700K-6500K",
            "CRI/TLCI": "95+/96+",
            "Dimming": "0-100%",
            "Cooling": "Active Cooling",
            "Control": "Wireless (Sidus Link App)",
            "Special Effects": "Yes",
            "Power Supply": "AC Power"
          }

        },
        {
          id: 74,
          title: "Aputure 300x",
          image: require("../assets/equipment/lights/aputure_300x.webp"),
          category: "Lights",
          description: "The Aputure 300x is a bi-color LED light offering adjustable color temperature from 2700K to 6500K. It features a high CRI/TLCI rating, providing accurate color reproduction for video and photography. The 300x supports brightness control with stepless dimming and includes built-in special effects, ideal for creating diverse lighting setups.",
          specifications: {
            "Power Output": "300W",
            "Color Temperature": "2700K-6500K",
            "CRI/TLCI": "95+/96+",
            "Dimming": "0-100%",
            "Cooling": "Active Cooling",
            "Control": "Wireless (Sidus Link App)",
            "Special Effects": "Yes",
            "Power Supply": "AC Power"
          }
        },
        {
          id: 75,
          title: "Aputure 600x",
          image: require("../assets/equipment/lights/aputure_600x.webp"),
          category: "Lights",
          description: "The Aputure 600x is a bi-color LED light with a wide color temperature range from 2700K to 6500K. It features high CRI/TLCI ratings for accurate color rendering and supports stepless dimming for precise brightness control. The 600x includes built-in special effects and can be controlled wirelessly via the Sidus Link app, suitable for professional video and photography.",
          specifications: {
            "Power Output": "600W",
            "Color Temperature": "2700K-6500K",
            "CRI/TLCI": "95+/96+",
            "Dimming": "0-100%",
            "Cooling": "Active Cooling",
            "Control": "Wireless (Sidus Link App)",
            "Special Effects": "Yes",
            "Power Supply": "AC Power"
          }
        },
        {
          id: 76,
          title: "Aputure 600d Pro",
          image: require("../assets/equipment/lights/aputure_600d_pro.webp"),
          category: "Lights",
          description: "The Aputure 600d Pro is a daylight-balanced LED light with 600W output equivalent brightness. It features a high CRI/TLCI rating for accurate color reproduction and supports DMX control for professional studio setups. The 600d Pro offers flicker-free operation, silent cooling fans, and robust build quality, making it ideal for film and broadcast lighting.",
          specifications: {
            "Power Output": "600W",
            "Color Temperature": "5600K",
            "CRI/TLCI": "96+/98+",
            "Dimming": "0-100%",
            "Cooling": "Active Silent Cooling",
            "Control": "DMX, Sidus Link App",
            "Special Effects": "Yes",
            "Power Supply": "AC Power, V-Mount Battery"
          }
        },
        {
          id: 77,
          title: "Aputure 1200d",
          image: require("../assets/equipment/lights/aputure_1200d.webp"),
          category: "Lights",
          description: "The Aputure 1200d is a powerful daylight LED light with 1200W output equivalent brightness. It features a high CRI/TLCI rating for accurate color rendering and supports DMX control for precise lighting adjustments. The 1200d offers silent operation, robust build quality, and flicker-free performance, making it suitable for large-scale film and broadcast productions.",
          specifications: {
            "Power Output": "1200W",
            "Color Temperature": "5600K",
            "CRI/TLCI": "96+/98+",
            "Dimming": "0-100%",
            "Cooling": "Active Silent Cooling",
            "Control": "DMX, Sidus Link App",
            "Special Effects": "Yes",
            "Power Supply": "AC Power"
          }
        },
        {
          id: 78,
          title: "Aputure Nova P 300c",
          image: require("../assets/equipment/lights/aputure_novap_300c.webp"),
          category: "Lights",
          description: "The Aputure Nova P 300c is a RGBWW LED panel light offering adjustable color temperature and full HSI color control. It features a high CRI/TLCI rating for accurate color reproduction and supports multiple lighting effects for creative flexibility. The 300c includes robust build quality, silent operation, and wireless control via the Sidus Link app, ideal for film and photography.",
          specifications: {
            "Power Output": "300W",
            "Color Temperature": "2000K-10000K",
            "CRI/TLCI": "95+/96+",
            "Dimming": "0-100%",
            "Cooling": "Active Silent Cooling",
            "Control": "Wireless (Sidus Link App)",
            "Special Effects": "Yes",
            "Power Supply": "AC Power"
          }
        },
        {
          id: 79,
          title: "Aputure Nova P 600c",
          image: require("../assets/equipment/lights/aputure_novap_600c.webp"),
          category: "Lights",
          description: "The Aputure Nova P 600c is a RGBWW LED panel light providing adjustable color temperature and full HSI color control. It features high CRI/TLCI ratings for accurate color rendering and supports multiple lighting effects for creative applications. The 600c includes robust build quality, silent operation, and wireless control via the Sidus Link app, suitable for professional film and photography.",
          specifications: {
            "Power Output": "600W",
            "Color Temperature": "2000K-10000K",
            "CRI/TLCI": "95+/96+",
            "Dimming": "0-100%",
            "Cooling": "Active Silent Cooling",
            "Control": "Wireless (Sidus Link App)",
            "Special Effects": "Yes",
            "Power Supply": "AC Power"
          }
        },
        {
          id: 80,
          title: "Godox SL 60",
          image: require("../assets/equipment/lights/godox_sl60.webp"),
          category: "Lights",
          description: "The Godox SL 60 is a daylight-balanced LED light with a high brightness output suitable for studio and video applications. It features a compact design, remote dimming control, and heat dissipation system for reliable performance. The SL 60 provides flicker-free illumination and is ideal for portrait, product, and commercial photography.",
          specifications: {
            "Power Output": "60W",
            "Color Temperature": "5600K",
            "CRI/TLCI": "93+/95+",
            "Dimming": "10-100%",
            "Cooling": "Active Cooling",
            "Control": "Wireless Remote Control",
            "Special Effects": "No",
            "Power Supply": "AC Power"
          }
        },
        {
          id: 81,
          title: "Godox SL 200 Light",
          image: require("../assets/equipment/lights/godox_sl200.webp"),
          category: "Lights",
          description: "The Godox SL 200 Light is a powerful daylight LED light providing high brightness output for professional studio and video productions. It features remote dimming control, silent operation, and a heat dissipation system for reliable performance. The SL 200 offers flicker-free illumination and is suitable for portrait, commercial, and film lighting applications.",
          specifications: {
            "Power Output": "200W",
            "Color Temperature": "5600K",
            "CRI/TLCI": "96+/97+",
            "Dimming": "0-100%",
            "Cooling": "Silent Heat Dissipation System",
            "Control": "Wireless Remote Control",
            "Special Effects": "No",
            "Power Supply": "AC Power"
          }

        },
        {
          id: 82,
          title: "Godox RGB Stick LC 500 R",
          image: require("../assets/equipment/lights/godox_lc500R.webp"),
          category: "Lights",
          description: "The Godox RGB Stick LC 500 R is a versatile LED light with full RGB color control and special lighting effects. It features adjustable color temperature, high brightness output, and wireless control via the Godox app. The LC 500 R is portable, easy to use, and ideal for creative lighting setups in photography and video production.",
          specifications: {
            "Color Control": "Full RGB",
            "Color Temperature": "Adjustable",
            "Brightness": "High Output",
            "Control Method": "Wireless (Godox app)",
            "Special Effects": "Yes",
            "Power Supply": "AC Power",
            "Portability": "Portable",
            "Application": "Photography, Video Production"
          }
        },
        {
          id: 83,
          title: "RGB (4 Feet Tube)",
          image: require("../assets/equipment/lights/nanlite_4_feet_rgb_tube.webp"),
          category: "Lights",
          description: "The RGB (4 Feet Tube) from Nanlite is a versatile LED tube light offering full RGB color control and adjustable color temperature. It features high brightness output and supports multiple lighting effects for creative applications.",
          specifications: {
            "Length": "4 Feet",
            "Color Control": "Full RGB",
            "Color Temperature": "Adjustable",
            "Brightness": "High Output",
            "Special Effects": "Yes",
            "Power Supply": "AC Power"
          }
        },
        {
          id: 84,
          title: "RGB (2 Feet Tube)",
          image: require("../assets/equipment/lights/nanlite_2_feet_rgb_tube.webp"),
          category: "Lights",
          description: "The RGB (2 Feet Tube) from Nanlite is a compact LED tube light offering full RGB color control and adjustable color temperature. It features high brightness output and supports multiple lighting effects for creative applications. The 2 Feet Tube is portable, easy to mount, and ideal for creating colorful and dynamic lighting setups in photography and video production.",
          specifications: {
            "Length": "2 Feet",
            "Color Control": "Full RGB",
            "Color Temperature": "Adjustable",
            "Brightness": "High Output",
            "Special Effects": "Yes",
            "Power Supply": "AC Power"
          }
        },
        {
          id: 85,
          title: "Aputure MT Pro Light (1 Foot)",
          image: require("../assets/equipment/lights/aputure_mt_pro.webp"),
          category: "Lights",
          description: "The Aputure MT Pro Light (1 Foot) is a compact and powerful LED light offering daylight-balanced illumination. It features a high CRI/TLCI rating for accurate color reproduction and supports adjustable brightness and color temperature. The MT Pro Light is portable, lightweight, and suitable for on-camera use or as a versatile lighting tool for small setups in photography and video.",
          specifications: {
            "Length": "1 Foot",
            "Color Temperature": "Adjustable",
            "CRI/TLCI": "High Rating",
            "Dimming": "Adjustable",
            "Power Supply": "AC/DC Power",
            "Special Features": "Lightweight and Portable"
          }
        },
        {
          id: 86,
          title: "Aputure Spot lens Mount",
          image: require("../assets/equipment/lights/aputure_spot_lensmount.webp"),
          category: "Lights",
          description: "The Aputure Spot Lens Mount is an accessory designed for the Aputure COB series lights, including the 120d and 300d models. It converts the wide beam angle of the light into a tighter spotlight, providing precise control over light direction and intensity. The Spot Lens Mount is ideal for accent lighting, product photography, and creating dramatic lighting effects.",
          specifications: {
            "Compatibility": "Aputure COB series lights (e.g., 120d, 300d)",
            "Beam Angle": "Converts wide beam angle to a tighter spotlight",
            "Control": "Provides precise control over light direction and intensity",
            "Applications": "Accent lighting, product photography, dramatic lighting effects"
          }
        },
        {
          id: 87,
          title: "Godox SK 400 Strobe Light",
          image: require("../assets/equipment/lights/godox_sk_400.webp"),
          category: "Lights",
          description: "The Godox SK 400 Strobe Light is a compact and powerful studio flash offering 400Ws of power output. It features a stable color temperature, fast recycling time, and support for multi-flash modes. The SK 400 is suitable for professional studio photography, portrait sessions, and commercial shoots, providing reliable and consistent lighting performance.",
          specifications: {
            "Power Output": "400Ws",
            "Color Temperature": "Stable",
            "Recycling Time": "Fast",
            "Flash Modes": "Supports multi-flash modes",
            "Applications": "Professional studio photography, portraits, commercial shoots",
            "Features": "Compact design"
          }
        },
        {
          id: 126,
          title: "Nanlux 2400B",
          image: require("../assets/equipment/lights/nanlux_2400.webp"),
          category: "Light",
          description: "The Nanlux 2400B is a high-performance bi-color LED light designed for professional use. With precise color temperature adjustment and robust output, it is ideal for film and photography applications. The durable build and multiple control options make it a versatile choice for any lighting setup.",
          specifications: {
            "Power Output": "2400W",
            "Color Temperature": "2700K-6500K",
            "CRI/TLCI": "96/97",
            "Dimming Range": "0-100%",
            "Weight": "15 kg",
            "Control Options": "DMX, WiFi, Bluetooth, Manual"
          }
        },
        {
          id: 127,
          title: "Nanlux 1200B",
          image: require("../assets/equipment/lights/nanlux_1200.webp"),
          category: "Light",
          description: "The Nanlux 1200B is a versatile bi-color LED light that combines power, reliability, and precision. Suitable for various professional applications, it provides consistent lighting and a wide color temperature range to adapt to any creative need.",
          specifications: {
            "Power Output": "1200W",
            "Color Temperature": "2700K-6500K",
            "CRI/TLCI": "96/97",
            "Dimming Range": "0-100%",
            "Weight": "10 kg",
            "Control Options": "DMX, WiFi, Bluetooth, Manual"
          }
        },
        {
          id: 128,
          title: "Nanlux 720B",
          image: require("../assets/equipment/lights/nanlux_720b.webp"),
          category: "Light",
          description: "The Nanlux 720B is a compact and efficient bi-color LED light with advanced features, making it an excellent choice for location shoots and studio setups. With robust performance and user-friendly controls, it supports creative and professional workflows.",
          specifications: {
            "Power Output": "720W",
            "Color Temperature": "2700K-6500K",
            "CRI/TLCI": "96/97",
            "Dimming Range": "0-100%",
            "Weight": "7 kg",
            "Control Options": "DMX, WiFi, Bluetooth, Manual"
          }
        },
        {
          id: 129,
          title: "Nanlite FC 500B",
          image: require("../assets/equipment/lights/nanlite_500b.webp"),
          category: "Light",
          description: "The Nanlite FC 500B bi-color LED delivers excellent light output and accurate color reproduction. It’s ideal for video and photo projects requiring portability, precision, and a consistent light source.",
          specifications: {
            "Power Output": "500W",
            "Color Temperature": "2700K-6500K",
            "CRI/TLCI": "95/97",
            "Dimming Range": "0-100%",
            "Weight": "5 kg",
            "Control Options": "DMX, WiFi, Bluetooth, Manual"
          }
        },
        {
          id: 130,
          title: "Nanlite 500C",
          image: require("../assets/equipment/lights/nanlite_500c.webp"),
          category: "Light",
          description: "The Nanlite 500C is a versatile RGBWW LED light that provides a full spectrum of color customization. Designed for creativity, it suits dynamic lighting effects and precise color matching for professional productions.",
          specifications: {
            "Power Output": "500W",
            "Color Temperature": "2700K-7500K",
            "CRI/TLCI": "95/97",
            "Dimming Range": "0-100%",
            "Weight": "6.5 kg",
            "Control Options": "DMX, WiFi, Bluetooth, Manual"
          }
        },
        {
          id: 131,
          title: "Nanlite 300B",
          image: require("../assets/equipment/lights/nanlite_300b.webp"),
          category: "Light",
          description: "The Nanlite 300B is a portable bi-color LED ideal for on-the-go lighting needs. Compact yet powerful, it delivers consistent performance with adjustable color temperature and intuitive controls.",
          specifications: {
            "Power Output": "300W",
            "Color Temperature": "2700K-6500K",
            "CRI/TLCI": "96/98",
            "Dimming Range": "0-100%",
            "Weight": "4 kg",
            "Control Options": "DMX, WiFi, Bluetooth, Manual"
          }
        },
        {
          id: 132,
          title: "Aputure 1200x",
          image: require("../assets/equipment/lights/aputure_1200x.webp"),
          category: "Light",
          description: "The Aputure 1200x is a flagship LED fixture with advanced bi-color capabilities and exceptional light output. Built for professionals, it offers powerful illumination, fine control, and seamless integration with modern workflows.",
          specifications: {
            "Power Output": "1200W",
            "Color Temperature": "2700K-6500K",
            "CRI/TLCI": "95/96",
            "Dimming Range": "0-100%",
            "Weight": "14 kg",
            "Control Options": "DMX, Sidus Link, Manual"
          }
        }
      ],
    },
    {
      category: "Monitor",
      items: [
        {
          id: 88,
          title: "Lilliput 4K Monitor",
          image: require("../assets/equipment/monitor/lilliput_4k.webp"),
          category: "Monitor",
          description: "The Lilliput 4K Monitor is a compact and high-resolution display designed for professional video production and photography. It features a 3840x2160 resolution screen that delivers sharp image quality with accurate color reproduction. The monitor supports various input sources and includes features like focus peaking, histogram, and audio meters. Its lightweight design makes it suitable for both on-set monitoring and studio use.",
          specifications: {
            "Resolution": "3840x2160 (4K)",
            "Screen Size": "Not specified",
            "Display Type": "LCD",
            "Color Accuracy": "High, accurate color reproduction",
            "Input/Output": "Various input sources supported",
            "Features": "Focus peaking, histogram, audio meters",
            "Use Case": "Professional video production and photography"
          }
        },
        {
          id: 89,
          title: "Atmos Shogun Connect Monitor",
          image: require("../assets/equipment/monitor/atmos_shogun_connect.webp"),
          category: "Monitor",
          description: "The Atmos Shogun Connect Monitor is a versatile 7-inch monitor/recorder designed for professional video workflows. It supports 4K recording and playback with a high-brightness touchscreen display. The monitor features advanced monitoring tools like waveform, vectorscope, and focus assist to ensure accurate image monitoring and adjustment. It's ideal for capturing high-quality video and offers seamless integration with various camera systems.",
          specifications: {
            "Screen Size": "7 inches",
            "Resolution": "High-brightness touchscreen",
            "Recording": "Supports 4K recording and playback",
            "Monitoring Tools": "Waveform, vectorscope, focus assist",
            "Integration": "Seamless integration with camera systems",
            "Use Case": "Professional video workflows"
          }
        },
        {
          id: 90,
          title: "Atmos Ninja 5 inch Monitor",
          image: require("../assets/equipment/monitor/atmos_ninja_5inch.webp"),
          category: "Monitor",
          description: "The Atmos Ninja 5 inch Monitor is a compact and lightweight on-camera monitor designed for filmmakers and content creators. It features a 1920x1080 resolution touchscreen display with high brightness for clear viewing in various lighting conditions. The monitor supports 4K HDMI input/output and offers professional monitoring tools like focus peaking, zebra stripes, and false color. Its small size and versatility make it ideal for use with DSLRs, mirrorless cameras, and cinema cameras.",
          specifications: {
            "Screen Size": "5 inches",
            "Resolution": "1920x1080",
            "Display Type": "Touchscreen LCD",
            "Brightness": "High brightness for clear viewing",
            "Input/Output": "Supports 4K HDMI input/output",
            "Monitoring Tools": "Focus peaking, zebra stripes, false color",
            "Use Case": "On-camera monitoring for filmmakers and content creators"
          }
        },
        {
          id: 91,
          title: "Atoms Sumo 19 Inch Monitor",
          image: require("../assets/equipment/monitor/atmos_sumo_19inch.webp"),
          category: "Monitor",
          description: "The Atmos Sumo 19 Inch Monitor is a large-screen HDR monitor designed for professional video production. It features a 1920x1080 resolution display with high brightness and wide color gamut support. The monitor offers HDR monitoring capabilities, including PQ and HLG formats, and supports 4K HDMI input/output. It includes advanced monitoring tools such as waveform, RGB parade, and 3D LUT support, making it suitable for use in grading suites and on-set monitoring.",
          specifications: {
            "Screen Size": "19 inches",
            "Resolution": "1920x1080",
            "Display Type": "HDR",
            "HDR Support": "PQ and HLG formats",
            "Color Gamut": "Wide color gamut support",
            "Input/Output": "Supports 4K HDMI input/output",
            "Monitoring Tools": "Waveform, RGB parade, 3D LUT support",
            "Use Case": "Professional video production, grading suites, on-set monitoring"
          }
        },
        {
          id: 92,
          title: "Director Monitor 17 inch",
          image: require("../assets/equipment/monitor/director_monitor_17inch.webp"),
          category: "Monitor",
          description: "The Director Monitor 17 inch is a large-format display designed for use in production environments and video editing suites. It features a high-resolution screen with wide viewing angles and accurate color reproduction. The monitor supports multiple input sources and includes features like picture-in-picture and audio monitoring. Its robust build and ergonomic design cater to the needs of directors, editors, and production teams.",
          specifications: {
            "Screen Size": "17 inches",
            "Resolution": "High-resolution screen",
            "Display Type": "Not specified",
            "Color Accuracy": "Accurate color reproduction",
            "Input Sources": "Supports multiple input sources",
            "Features": "Picture-in-picture, audio monitoring",
            "Use Case": "Production environments, video editing suites"
          }

        },
        {
          id: 93,
          title: "Shinobi 5 inch Monitor",
          image: require("../assets/equipment/monitor/shinobi_5inch_monitor.webp"),
          category: "Monitor",
          description: "The Shinobi 5 inch Monitor from Atmos is a lightweight on-camera monitor designed for vloggers, photographers, and filmmakers. It features a Full HD 1920x1080 touchscreen display with high brightness and 1000:1 contrast ratio. The monitor supports HDMI input and includes monitoring tools like focus peaking, 4:1 zoom, and safe areas. Its compact size and durability make it suitable for use in various shooting conditions, providing reliable image monitoring and playback.",
          specifications: {
            "Screen Size": "5 inches",
            "Resolution": "1920x1080 (Full HD)",
            "Display Type": "Touchscreen LCD",
            "Brightness": "High brightness",
            "Contrast Ratio": "1000:1",
            "Input": "HDMI input",
            "Monitoring Tools": "Focus peaking, 4:1 zoom, safe areas",
            "Use Case": "On-camera monitoring for vloggers, photographers, filmmakers"
          }
        },
      ],
    },
    {
      category: "Sound",
      items: [
        {
          id: 94,
          title: "Zoom H6 Recorder",
          image: require("../assets/equipment/sound/zoom_h6_recorder.webp"),
          category: "Sound",
          description: "The Zoom H6 Recorder is a versatile handheld audio recorder ideal for professional audio recording in various environments. It features interchangeable microphone capsules, allowing flexibility in capturing different sound sources. The recorder supports up to six simultaneous channels and offers high-quality audio recording at resolutions up to 24-bit/96kHz. It includes features like built-in effects, low-cut filtering, and a color LCD screen for easy navigation and monitoring.",
          specifications: {
            "Channels": "Up to 6 simultaneous channels",
            "Recording Resolution": "Up to 24-bit/96kHz",
            "Microphone Capsules": "Interchangeable capsules for various sound sources",
            "Features": "Built-in effects, low-cut filtering, color LCD screen",
            "Use Case": "Professional audio recording in various environments"
          }
        },
        {
          id: 95,
          title: "Sennheiser Handle Cordless Mic",
          image: require("../assets/equipment/sound/sennhieser_cordless_mic.webp"),
          category: "Sound",
          description: "The Sennheiser Handle Cordless Mic is a professional-grade wireless microphone system designed for clear and reliable audio transmission. It features a compact handheld transmitter with a dynamic microphone capsule and a rugged receiver unit with antenna diversity for stable reception. The mic system operates on UHF frequencies, offering excellent signal clarity and range. It's suitable for live performances, events, and broadcast applications.",
          specifications: {
            "Microphone Type": "Dynamic handheld transmitter",
            "Frequency Range": "UHF frequencies",
            "Receiver": "Rugged unit with antenna diversity",
            "Features": "Compact design, reliable audio transmission",
            "Use Case": "Live performances, events, broadcast"
          }
        },
        {
          id: 96,
          title: "Sennheiser wireless mic G4",
          image: require("../assets/equipment/sound/sennhieser_wireless_micg4.webp"),
          category: "Sound",
          description: "The Sennheiser Wireless Mic G4 is a professional wireless microphone system known for its reliability and sound quality. It includes a bodypack transmitter with a lavaliere microphone and a robust receiver unit with adaptive diversity technology. The mic system operates on UHF frequencies, providing clear and interference-free audio transmission. It's suitable for film production, ENG (Electronic News Gathering), and live sound applications.",
          specifications: {
            "Microphone Type": "Lavaliere microphone with bodypack transmitter",
            "Frequency Range": "UHF frequencies",
            "Receiver": "Robust unit with adaptive diversity technology",
            "Features": "Interference-free transmission, high sound quality",
            "Use Case": "Film production, ENG, live sound"
          }
        },
        {
          id: 97,
          title: "Rode wireless Go II dual channel",
          image: require("../assets/equipment/sound/rode_wireless_go_2.webp"),
          category: "Sound",
          description: "The Rode Wireless Go II dual channel is an ultra-compact and versatile wireless microphone system designed for content creators and filmmakers. It features two transmitters and a single receiver, allowing simultaneous dual-channel recording. The system offers a range of up to 200m and includes built-in recording capabilities to microSD cards, making it suitable for interviews, vlogging, and video production in challenging environments.",
          specifications: {
            "Channels": "Dual-channel (two transmitters, one receiver)",
            "Transmission Range": "Up to 200m",
            "Recording": "Built-in recording to microSD cards",
            "Features": "Ultra-compact design, versatile applications",
            "Use Case": "Interviews, vlogging, video production"
          }
        },
        {
          id: 98,
          title: "Rode On Camera Video Mic",
          image: require("../assets/equipment/sound/rode_oncamera_video_mic.webp"),
          category: "Sound",
          description: "The Rode On Camera Video Mic is a compact and lightweight shotgun microphone designed for use with DSLR cameras and camcorders. It features a supercardioid pickup pattern to capture clear directional audio while minimizing background noise. The microphone operates on plug-in power (no batteries required) and includes a shock mount for reducing handling noise. It's ideal for run-and-gun filmmaking, interviews, and outdoor recording.",
          specifications: {
            "Microphone Type": "Shotgun microphone",
            "Pickup Pattern": "Supercardioid",
            "Power": "Plug-in power (no batteries required)",
            "Features": "Lightweight, shock mount included",
            "Use Case": "DSLR cameras, camcorders, run-and-gun filmmaking"
          }
        },
        {
          id: 99,
          title: "Audio Mixer 552",
          image: require("../assets/equipment/sound/audio_mixer_552.webp"),
          category: "Sound",
          description: "The Audio Mixer 552 from Sound Devices is a portable five-channel audio mixer designed for professional film and video production. It features high-quality mic preamps, extensive input/output options, and built-in limiters for preventing distortion. The mixer includes analog and digital outputs, timecode support, and a headphone monitor with adjustable volume. Its compact size and robust build make it suitable for location sound recording and ENG applications.",
          specifications: {
            "Channels": "Five-channel mixer",
            "Mic Preamps": "High-quality mic preamps",
            "Inputs/Outputs": "Extensive input/output options (analog, digital)",
            "Features": "Built-in limiters, timecode support, headphone monitor",
            "Use Case": "Professional film and video production, ENG"
          }
        },
        {
          id: 100,
          title: "Audio Mixer 664",
          image: require("../assets/equipment/sound/audio_mixer_664.webp"),
          category: "Sound",
          description: "The Audio Mixer 664 from Sound Devices is a six-channel portable mixer and digital recorder designed for demanding film and television productions. It features high-resolution audio recording up to 24-bit/48kHz, multiple mic/line inputs, and comprehensive mixing controls. The mixer includes timecode support, multi-track recording capabilities, and flexible routing options. Its rugged construction and advanced features cater to professional sound recording applications.",
          specifications: {
            "Channels": "Six-channel mixer",
            "Recording Resolution": "Up to 24-bit/48kHz",
            "Inputs/Outputs": "Multiple mic/line inputs, flexible routing",
            "Features": "Timecode support, multi-track recording",
            "Use Case": "Film and television productions, professional sound recording"
          }
        },
        {
          id: 101,
          title: "Audio Mixer Mix Pre 10 II",
          image: require("../assets/equipment/sound/audio_mixer_mix_pre_10_2.webp"),
          category: "Sound",
          description: "The Audio Mixer Mix Pre 10 II from Sound Devices is a compact and versatile audio recorder/mixer designed for field recording, podcasting, and music production. It features eight high-quality mic/line inputs, flexible mixing controls, and up to 12 tracks of recording. The mixer includes Kashmir preamps for low-noise, high-gain performance and supports timecode synchronization and USB audio streaming. Its intuitive touchscreen interface and rugged build make it ideal for mobile recording setups.",
          specifications: {
            "Channels": "Up to 12 tracks recording",
            "Inputs": "Eight high-quality mic/line inputs",
            "Preamps": "Kashmir preamps",
            "Features": "Flexible mixing controls, timecode sync, USB audio streaming",
            "Use Case": "Field recording, podcasting, music production"
          }
        },
        {
          id: 102,
          title: "Podcast Mic",
          image: require("../assets/equipment/sound/podcast_mic.webp"),
          category: "Sound",
          description: "The Podcast Mic is a professional-grade microphone designed specifically for podcasting and voice recording applications. It features a large-diaphragm condenser capsule for capturing clear and detailed audio, ideal for vocals and spoken word. The mic includes a shock mount to reduce handling noise and a pop filter to minimize plosives. Its USB connectivity ensures easy plug-and-play operation with computers and audio interfaces, making it suitable for home studios and podcast setups.",
          specifications: {
            "Microphone Type": "Large-diaphragm condenser microphone",
            "Features": "Shock mount, pop filter, USB connectivity",
            "Use Case": "Podcasting, voice recording"
          }
        },
      ],
    },
    {
      category: "Drone",
      items: [
        {
          id: 103,
          title: "DJI Mavic 3 Classic",
          image: require("../assets/equipment/drone/dji_mavic3_classic.webp"),
          category: "Drone",
          description: "The DJI Mavic 3 Classic is a premium consumer drone known for its advanced features and robust build. It boasts a Hasselblad camera with a large 1-inch CMOS sensor, capable of capturing stunning 20MP photos and 5.1K video at 50fps. The drone offers up to 46 minutes of flight time per battery charge and includes obstacle sensors for enhanced safety during flight. It features advanced autonomous flight modes, including ActiveTrack 4.0 and FocusTrack, making it ideal for aerial photography and videography.",
          specifications: {
            "Camera": "Hasselblad camera with 1-inch CMOS sensor",
            "Photo Resolution": "20MP",
            "Video Resolution": "5.1K @ 50fps",
            "Flight Time": "Up to 46 minutes",
            "Obstacle Sensors": "Yes, for enhanced safety",
            "Autonomous Flight Modes": "ActiveTrack 4.0, FocusTrack",
            "Features": "Advanced imaging capabilities, robust build"
          }
        },
        {
          id: 104,
          title: "DJI Mavic Pro 2",
          image: require("../assets/equipment/drone/dji_mavic_pro2.webp"),
          category: "Drone",
          description: "The DJI Mavic Pro 2 is a compact yet powerful drone designed for professional aerial photography and filmmaking. It features a Hasselblad camera with a 1-inch CMOS sensor, capable of capturing high-resolution 20MP photos and 4K video at 30fps. The drone offers up to 31 minutes of flight time and includes omnidirectional obstacle sensing for safe and efficient flight operations. It supports advanced intelligent flight modes like Hyperlapse, QuickShots, and ActiveTrack, making it versatile for various creative applications.",
          specifications: {
            "Camera": "Hasselblad camera with 1-inch CMOS sensor",
            "Photo Resolution": "20MP",
            "Video Resolution": "4K @ 30fps",
            "Flight Time": "Up to 31 minutes",
            "Obstacle Sensors": "Omnidirectional obstacle sensing",
            "Intelligent Flight Modes": "Hyperlapse, QuickShots, ActiveTrack",
            "Features": "Compact design, professional-grade imaging"
          }
        },
        {
          id: 105,
          title: "DJI Mavic Pro 3",
          image: require("../assets/equipment/drone/dji_mavic_pro3.webp"),
          category: "Drone",
          description: "The DJI Mavic Pro 3 is a next-generation consumer drone featuring advanced technology and improved performance. It boasts a compact design with a high-quality camera system capable of capturing 4K video at 60fps and 20MP photos. The drone offers up to 40 minutes of flight time and includes advanced obstacle avoidance sensors for safe and precise flying. It supports intelligent shooting modes like ActiveTrack, Point of Interest (POI), and QuickShots, catering to both hobbyists and professional drone users.",
          specifications: {
            "Camera": "High-quality camera system",
            "Photo Resolution": "20MP",
            "Video Resolution": "4K @ 60fps",
            "Flight Time": "Up to 40 minutes",
            "Obstacle Sensors": "Advanced obstacle avoidance sensors",
            "Intelligent Shooting Modes": "ActiveTrack, POI, QuickShots",
            "Features": "Next-generation technology, compact design"
          }
        },
        {
          id: 106,
          title: "DJI Mavic 3 Air",
          image: require("../assets/equipment/drone/dji_mavic3_air.webp"),
          category: "Drone",
          description: "The DJI Mavic 3 Air is a compact and lightweight drone designed for casual and recreational use. It features a stabilized camera capable of capturing smooth 4K video and 12MP photos. The drone offers up to 30 minutes of flight time and includes intuitive flight modes like QuickShots and SmartCapture for easy aerial photography. It incorporates obstacle sensors for safe flying and supports DJI Fly app integration, making it ideal for beginners and hobbyist drone pilots.",
          specifications: {
            "Camera": "Stabilized camera",
            "Photo Resolution": "12MP",
            "Video Resolution": "4K",
            "Flight Time": "Up to 30 minutes",
            "Obstacle Sensors": "Obstacle sensors",
            "Intelligent Flight Modes": "QuickShots, SmartCapture",
            "Features": "Compact, lightweight design, DJI Fly app integration"
          }
        },
      ],
    },
    {
      category: "Accessories",
      items: [
        {
          id: 107,
          title: "DJI Ronin S",
          image: require("../assets/equipment/accessories/DJI_Ronin_S.webp"),
          category: "Accessories",
          description: "The DJI Ronin S is a popular single-handed gimbal stabilizer designed for DSLR and mirrorless cameras. It provides smooth and stable footage with its 3-axis stabilization system, allowing for creative camera movements and professional-quality shots. The Ronin S features multiple modes for different shooting scenarios, including Pan Follow, Lock, and POV Mode. It supports cameras up to 8 lbs (3.6 kg) and offers intuitive controls via its ergonomic design.",
          specifications: {
            "Stabilization System": "3-axis",
            "Maximum Payload": "Up to 8 lbs (3.6 kg)",
            "Modes": "Pan Follow, Lock, POV Mode",
            "Compatibility": "DSLR and mirrorless cameras",
            "Features": "Ergonomic design, intuitive controls"
          }
        },
        {
          id: 108,
          title: "Zhiyun Crane 2",
          image: require("../assets/equipment/accessories/zhiyun_crane_2.webp"),
          category: "Accessories",
          description: "The Zhiyun Crane 2 is a versatile 3-axis handheld gimbal stabilizer designed for DSLR and mirrorless cameras. It supports cameras weighing up to 7 lbs (3.2 kg) and features advanced stabilization technology for smooth and steady footage. The Crane 2 offers multiple intelligent shooting modes, including Follow Focus Control, Full-Range POV Mode, and Timelapse. It includes an OLED display for real-time monitoring and intuitive control of camera settings.",
          specifications: {
            "Stabilization System": "3-axis",
            "Maximum Payload": "Up to 7 lbs (3.2 kg)",
            "Intelligent Modes": "Follow Focus Control, Full-Range POV Mode, Timelapse",
            "Display": "OLED display for real-time monitoring",
            "Compatibility": "DSLR and mirrorless cameras",
            "Features": "Advanced stabilization technology"
          }
        },
        {
          id: 109,
          title: "Zhiyun Crane 3",
          image: require("../assets/equipment/accessories/zhiyun_crane_3.webp"),
          category: "Accessories",
          description: "The Zhiyun Crane 3 is a professional-grade 3-axis gimbal stabilizer designed for heavy DSLR and cinema cameras. It supports payloads up to 14.3 lbs (6.5 kg) and features a modular design for versatile configurations. The Crane 3 offers innovative features like SmartSling Handle and ViaTouch Control System, allowing for precise camera movements and wireless camera control. It includes multiple creative modes such as Vortex Mode and Motion Memory for complex shots.",
          specifications: {
            "Stabilization System": "3-axis",
            "Maximum Payload": "Up to 14.3 lbs (6.5 kg)",
            "Modular Design": "Yes, for versatile configurations",
            "Control System": "ViaTouch Control System",
            "Features": "SmartSling Handle, Vortex Mode, Motion Memory",
            "Compatibility": "Heavy DSLR and cinema cameras"
          }
        },
        {
          id: 110,
          title: "Slider",
          image: require("../assets/equipment/accessories/slider.webp"),
          category: "Accessories",
          description: "A slider is a essential accessory for cinematographers and videographers, used to add smooth, cinematic motion to camera shots. Sliders come in various lengths and designs, typically featuring a track or rail system on which the camera moves horizontally or vertically. They are used for dynamic camera movements, creating visually appealing slides, pans, and tracking shots. Sliders enhance production value by adding professional-grade motion to video footage.",
          specifications: {
            "Functionality": "Adds smooth, cinematic motion to camera shots",
            "Design": "Track or rail system for horizontal or vertical movement",
            "Usage": "Dynamic camera movements, slides, pans, tracking shots",
            "Benefits": "Enhances production value with professional-grade motion"
          }
        },
        {
          id: 111,
          title: "DJI RS 2 Gimbal",
          image: require("../assets/equipment/accessories/DJI_rs2_gimbal.webp"),
          category: "Accessories",
          description: "The DJI RS 2 is a lightweight and portable 3-axis gimbal stabilizer designed for mirrorless and DSLR cameras. It features a carbon fiber construction for durability and supports cameras weighing up to 10 lbs (4.5 kg). The RS 2 offers advanced stabilization technology and includes intelligent shooting modes like Titan Stabilization, Time Tunnel, and ActiveTrack 3.0. It features a built-in touchscreen for quick settings adjustment and offers up to 12 hours of battery life.",
          specifications: {
            "Stabilization System": "3-axis",
            "Maximum Payload": "Up to 10 lbs (4.5 kg)",
            "Material": "Carbon fiber construction",
            "Intelligent Modes": "Titan Stabilization, Time Tunnel, ActiveTrack 3.0",
            "Display": "Built-in touchscreen for settings adjustment",
            "Battery Life": "Up to 12 hours"
          }
        },
        {
          id: 112,
          title: "DJI RS3 Pro Gimbal",
          image: require("../assets/equipment/accessories/dji_rs3_pro.webp"),
          category: "Accessories",
          description: "The DJI RS3 Pro is a professional-grade 3-axis gimbal stabilizer designed for cinema cameras and heavy payloads. It supports cameras weighing up to 18 lbs (8.2 kg) and features a robust design for stable and smooth footage in demanding environments. The RS3 Pro offers advanced features like SuperSmooth mode, DJI RavenEye Image Transmission, and Force Mobile, allowing for precise control and wireless camera operation. It includes versatile mounting options and ergonomic design for extended use.",
          specifications: {
            "Stabilization System": "3-axis",
            "Maximum Payload": "Up to 18 lbs (8.2 kg)",
            "Features": "SuperSmooth mode, DJI RavenEye Image Transmission, Force Mobile",
            "Compatibility": "Cinema cameras and heavy payloads",
            "Mounting Options": "Versatile mounting options",
            "Design": "Robust design for stability in demanding environments"
          }
        },
        {
          id: 113,
          title: "DJI RS3 Pro Combo",
          image: require("../assets/equipment/accessories/dji_rs3_pro_combo.webp"),
          category: "Accessories",
          description: "The DJI RS3 Pro Combo includes the RS3 Pro gimbal stabilizer along with additional accessories for enhanced functionality. It features the RavenEye Image Transmission System for real-time monitoring and camera control, making it ideal for professional filmmaking and broadcast applications. The RS3 Pro Combo offers compatibility with a wide range of cinema cameras and includes a dedicated carrying case for easy transport and storage.",
          specifications: {
            "Stabilization System": "3-axis",
            "Additional Accessories": "Includes RavenEye Image Transmission System",
            "Compatibility": "Wide range of cinema cameras",
            "Carrying Case": "Dedicated case for transport and storage"
          }
        },
        {
          id: 114,
          title: "DJI RS4 Pro Gimbal",
          image: require("../assets/equipment/accessories/dji_rs4_pro.webp"),
          category: "Accessories",
          description: "The DJI RS4 Pro is an advanced 3-axis gimbal stabilizer designed for high-performance cinema cameras and heavy payloads. It supports cameras weighing up to 25 lbs (11.3 kg) and features DJI's latest stabilization technology for precise and smooth footage. The RS4 Pro offers intelligent shooting modes like ActiveTrack 4.0, Titan Stabilization, and Time Tunnel, enhancing creative possibilities for professional filmmakers and cinematographers.",
          specifications: {
            "Stabilization System": "3-axis",
            "Maximum Payload": "Up to 25 lbs (11.3 kg)",
            "Intelligent Modes": "ActiveTrack 4.0, Titan Stabilization, Time Tunnel",
            "Compatibility": "High-performance cinema cameras and heavy payloads",
            "Features": "Latest stabilization technology"
          }
        },
        {
          id: 115,
          title: "Crane 3S gimbal",
          image: require("../assets/equipment/accessories/crane_3s_gimbal.webp"),
          category: "Accessories",
          description: "The Crane 3S is a powerful 3-axis gimbal stabilizer designed for heavy DSLR and cinema cameras. It supports payloads up to 14 lbs (6.5 kg) and features modular design with versatile handling modes. The Crane 3S includes Sync Motion and Image Transmission System, providing precise control over camera movements and real-time monitoring. It offers multiple creative shooting modes such as Panorama, Vortex Mode, and Timelapse, catering to professional filmmakers.",
          specifications: {
            "Stabilization System": "3-axis",
            "Maximum Payload": "Up to 14 lbs (6.5 kg)",
            "Modular Design": "Yes, with versatile handling modes",
            "Features": "Sync Motion, Image Transmission System",
            "Creative Modes": "Panorama, Vortex Mode, Timelapse"
          }
        },
        {
          id: 116,
          title: "Shoulder Rig Combo Kit",
          image: require("../assets/equipment/accessories/shoulder_rig_kit.webp"),
          category: "Accessories",
          description: "A shoulder rig combo kit is a comprehensive setup for shoulder-mounted camera operation, providing stability and comfort during extended shooting sessions. It typically includes a shoulder mount, dual handles, follow focus, matte box, and counterweights. The rig allows for precise camera control and smooth movements, enhancing filmmaking capabilities and ensuring professional-grade video production.",
          specifications: {
            "Components": "Shoulder mount, dual handles, follow focus, matte box, counterweights",
            "Functionality": "Stability and comfort for shoulder-mounted camera operation",
            "Features": "Enhanced camera control, smooth movements",
            "Application": "Professional-grade video production"
          }
        },
        {
          id: 117,
          title: "Easy Rig",
          image: require("../assets/equipment/accessories/easyrig.webp"),
          category: "Accessories",
          description: "An Easy Rig is a support system designed to reduce the strain on the operator when using heavy camera rigs or gimbals. It consists of a backpack-style harness with an adjustable arm that suspends the camera and redistributes its weight to the operator's hips and shoulders. Easy Rigs are commonly used in filmmaking and video production to achieve stable shots while allowing for greater mobility and comfort during extended shoots.",
          specifications: {
            "Design": "Backpack-style harness with adjustable arm",
            "Function": "Reduces strain on operator, redistributes weight",
            "Application": "Used with heavy camera rigs or gimbals",
            "Benefits": "Stable shots, greater mobility, comfort during extended shoots"
          }
        },
        {
          id: 118,
          title: "Camera Cage",
          image: require("../assets/equipment/accessories/camera_cage.webp"),
          category: "Accessories",
          description: "A camera cage is a protective and versatile mounting system for DSLR and mirrorless cameras, offering multiple attachment points for accessories like lights, monitors, and microphones. It provides enhanced stability and protection while allowing for modular customization of camera setups. Camera cages are essential for professional filmmakers and videographers seeking to expand their camera rigging options and improve overall shooting versatility.",
          specifications: {
            "Mounting System": "Versatile mounting with multiple attachment points",
            "Compatibility": "DSLR and mirrorless cameras",
            "Features": "Enhanced stability, modular customization",
            "Application": "Expanding camera rigging options, improving shooting versatility"
          }
        },
        {
          id: 119,
          title: "Camera Dual Handle for Gimbal",
          image: require("../assets/equipment/accessories/camera_dual_handle.webp"),
          category: "Accessories",
          description: "A camera dual handle for gimbals enhances stability and control over camera movements during shooting. It provides a comfortable grip for operators, allowing for precise adjustments and smooth panning. Dual handles distribute the weight of the gimbal and camera setup evenly, reducing fatigue and enabling longer shooting sessions without compromising stability. They are essential accessories for achieving professional-quality results in filmmaking and videography.",
          specifications: {
            "Function": "Enhances stability and control over camera movements",
            "Grip": "Comfortable grip for operators",
            "Weight Distribution": "Even distribution of gimbal and camera weight",
            "Benefits": "Reduces fatigue, enables longer shooting sessions",
            "Application": "Professional-quality results in filmmaking and videography"
          }
        },
        {
          id: 120,
          title: "Video Tripod",
          image: require("../assets/equipment/accessories/tripod.webp"),
          category: "Accessories",
          description: "A video tripod is an essential accessory for stable camera support during video production. Unlike photo tripods, video tripods feature fluid heads for smooth panning and tilting movements, essential for professional-quality video capture. They are adjustable in height and often include spreaders or mid-level spreaders for added stability. Video tripods support a wide range of cameras and are suitable for various shooting environments and applications.",
          specifications: {
            "Features": "Fluid head for smooth panning and tilting movements",
            "Adjustability": "Height adjustment, spreaders or mid-level spreaders",
            "Compatibility": "Supports a wide range of cameras",
            "Application": "Professional-quality video capture, various shooting environments"
          }
        },
        {
          id: 121,
          title: "C Motion wireless Follow Focus",
          image: require("../assets/equipment/accessories/cmotion_wireless_follow_focus.webp"),
          category: "Accessories",
          description: "A C Motion wireless follow focus system allows for precise control over focus adjustments during video production. It consists of a wireless hand controller and motorized lens gear, enabling the focus puller to maintain sharp focus on moving subjects or change focus smoothly between different points of interest. Wireless follow focus systems are essential tools for cinematographers and videographers seeking to achieve professional-level focus control and enhance storytelling.",
          specifications: {
            "System": "Wireless hand controller, motorized lens gear",
            "Control": "Precise control over focus adjustments",
            "Application": "Video production, professional-level focus control",
            "Benefits": "Maintains sharp focus on moving subjects, smooth focus adjustments"
          }
        },
        {
          id: 122,
          title: "Clipon Matt Box",
          image: require("../assets/equipment/accessories/matt_box.webp"),
          category: "Accessories",
          description: "A clip-on matte box is an accessory mounted in front of the camera lens to control light and reduce glare during filming. It holds filters and prevents unwanted light from entering the lens, improving image quality and allowing for creative effects. Matte boxes are adjustable and compatible with various lens diameters, providing flexibility in cinematography and video production. They are essential for achieving professional-grade visuals in diverse shooting conditions.",
          specifications: {
            "Mounting": "Clip-on in front of the camera lens",
            "Function": "Controls light, reduces glare during filming",
            "Compatibility": "Adjustable, compatible with various lens diameters",
            "Benefits": "Improves image quality, allows for creative effects"
          }
          
        },
        {
          id: 123,
          title: "Macbook Pro",
          image: require("../assets/equipment/accessories/macbook_pro.webp"),
          category: "Accessories",
          description: "The MacBook Pro is a powerful and versatile laptop designed for professional use in creative industries such as filmmaking, photography, and video editing. It features high-performance processors, vivid Retina displays, and fast SSD storage, providing robust computing power for demanding applications. The MacBook Pro is equipped with Thunderbolt ports for fast data transfer and supports external displays and peripherals, making it an ideal choice for editing and production work on the go.",
          specifications: {
            "Usage": "Professional use in creative industries",
            "Processor": "High-performance processors",
            "Display": "Vivid Retina display",
            "Storage": "Fast SSD storage",
            "Ports": "Thunderbolt ports for fast data transfer",
            "Application": "Filmmaking, photography, video editing"
          }
        },
        {
          id: 134,
          title: "Tilta Advance Ring Grip",
          image: require("../assets/equipment/accessories/tilta_advance_ring_grip.webp"),
          category: "Accessory",
          description: "The Tilta Advance Ring Grip is an ergonomic support system for gimbals and cameras. It improves stability and control during shoots, making handheld operations comfortable and precise.",
          specifications: {
            "Material": "Aluminum Alloy",
            "Compatibility": "Tilta DJI RS2/RS3",
            "Weight": "1.5 kg",
            "Features": "Wireless control, customizable buttons"
          }
        },
        {
          id: 135,
          title: "Car Suction Mount",
          image: require("../assets/equipment/accessories/car_suction_mount.webp"),
          category: "Accessory",
          description: "The Car Suction Mount is a secure mounting solution for cameras and equipment. Ideal for vehicle shoots, it offers robust hold, adjustability, and compatibility with various gear.",
          specifications: {
            "Material": "Aluminum Alloy, Rubber Suction",
            "Compatibility": "Universal",
            "Weight": "2 kg",
            "Features": "360° rotation, heavy-duty suction cups"
          }
        }
      ],
    },
  ],
};
